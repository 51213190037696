import * as React from "react"

// External packages
import { ShippingOption } from "@medusajs/medusa"
import { useCartShippingOptions } from "medusa-react"
import { z } from "zod"
import { Box, Flex, Paragraph, Text } from "theme-ui"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Utilities
import { formatMoneyAmount } from "../../../utils/prices"
import { canSplitOrder, hasItemAboveLimit } from "../../../utils/split-order"
import { hasCartGiftcard } from "../utils/cart/hasCartGiftcard"

// Services
import MedusaRequest from "../../../services/request"
import {
  trackCheckoutStepCompleted,
  trackCheckoutStepViewed,
} from "../../../services/analytics"

// Components
import Form from "../form/Form"
import { Button } from "../Button"
import SubmitButton from "../form/SubmitButton"
import RadioField from "../form/RadioField"
import { RadioIndicator, RadioInput } from "../ui/Radio"
import ParcelWidget from "./ParcelWidget"
import { CheckoutStepAction } from "./Checkout"
import { GiftWrapping } from "./GiftWrapping"
import { CarbonEmissionCollapse } from "../CarbonEmissionCollapse"
import { CarbonEmissionTag } from "../CarbonEmissionTag"

export interface ShippingOptionWithPrice extends ShippingOption {
  price_incl_tax: number
}

const shippingSchema = z.object({
  "shipping-method": z.string(),
  "split-order": z.string().optional(),
})

const giftCardShippingSchema = z.object({
  "split-order": z.string().optional(),
})

const Shipping: React.FC<CheckoutStepAction> = ({
  previousStep,
  nextStep,
  previousStepLabel,
  nextStepLabel,
  isInEditable,
}) => {
  const [hasGiftcardFieldError, setHasGiftcardFieldError] =
    React.useState(false)
  const {
    cart,
    freeShippingLimitThreshold,
    addShippingMethod,
    isAboveFreeShippingLimit,
    updateCart,
  } = useStore()

  const shippingOptions = useCartShippingOptions(cart?.id, {
    onError: (error) => {
      console.log(error)
    },
  })

  const [parcelShopOptions, setParcelShopOptions] = React.useState({
    options: [],
    loading: false,
    data: {},
  })

  const [parcelWidgetHasError, setParcelWidgetHasError] = React.useState(false)
  const [splitOrderValue, setSplitOrderValue] = React.useState("1")
  const getNonGiftcardShippingMethods = (
    shippingOptions,
    excludeDDP = false,
    onlyDDP = false
  ) => {
    let nonGiftcardShippingMethods = shippingOptions?.shipping_options?.filter(
      (i) => {
        return i.name !== "Printed Copy" && i.name !== "Digital Copy"
      }
    )

    if (onlyDDP) {
      return nonGiftcardShippingMethods.filter(
        (i) => i.metadata?.is_ddp === "true"
      )
    }

    if (excludeDDP) {
      nonGiftcardShippingMethods = nonGiftcardShippingMethods.filter(
        (i) => i.metadata?.is_ddp !== "true"
      )
    }

    return nonGiftcardShippingMethods
  }
  const sortShippingOptions = (shippingOptions: ShippingOption[]) => {
    if (!shippingOptions?.length) {
      return shippingOptions
    }

    const freeShipping = shippingOptions.find(
      (i) =>
        i.name?.toLowerCase()?.includes("free") &&
        !i.name?.toLowerCase()?.includes("parcel")
    )
    const parcelShop = shippingOptions.find((i) =>
      i.name?.toLowerCase()?.includes("parcel")
    )
    const express = shippingOptions.find(
      (i) =>
        i.name?.toLowerCase()?.includes("express") &&
        !i.name?.toLowerCase()?.includes("free")
    )

    const rest = shippingOptions.filter(
      (i) =>
        i.id !== parcelShop?.id &&
        i.id !== express?.id &&
        i.id !== freeShipping?.id
    )

    return [freeShipping, parcelShop, ...rest, express].filter((i) =>
      Boolean(i)
    )
  }
  const isOnlyGiftCardShippingMethods = shippingOptions.shipping_options?.every(
    (option) => option.name === "Digital Copy" || option.name === "Printed Copy"
  )

  const handleParcelShopSelected = (value) => {
    const option = parcelShopOptions.options.find(
      (o) => o.drop_point_id === value
    )

    if (value) {
      setParcelWidgetHasError(false)
    }

    setParcelShopOptions({
      ...parcelShopOptions,
      data: {
        drop_point_id: option?.drop_point_id,
        drop_point_name: option?.name,
        drop_point_address: option?.address_1,
        drop_point_zip: option?.zip,
        drop_point_city: option?.city,
        drop_point_country_code: option?.country_code,
      },
    })
  }

  const loadParcelShops = async (fromOption, postal = null) => {
    setParcelShopOptions({
      ...parcelShopOptions,
      loading: true,
    })

    const address = cart?.shipping_address
    const q = []
    q.push(`postal_code=${postal || address.postal_code}`)
    q.push(`street=${address.address_1}`)
    q.push(`country_code=${address.country_code.toUpperCase()}`)

    MedusaRequest(
      "GET",
      `/webshipper/drop-points/${fromOption.data.webshipper_id}?${q.join("&")}`
    )
      .then(({ data }) => {
        const update = {
          ...parcelShopOptions,
          options: data.drop_points.map((s) => {
            s.id = s.drop_point_id
            return s
          }),
          loading: false,
        }
        setParcelShopOptions(update)
      })
      .catch((err) => {
        console.log(err)
        setParcelShopOptions({
          ...parcelShopOptions,
          loading: false,
        })
      })
  }

  React.useEffect(() => {
    if (
      shippingOptions?.shipping_options?.length &&
      !parcelShopOptions?.options?.length
    ) {
      const packShop = shippingOptions.shipping_options.find(
        (s) => s.data && s.data.require_drop_point
      )

      if (packShop) {
        loadParcelShops(packShop)
      }
    }

    if (!isInEditable) {
      trackCheckoutStepViewed(cart, 2, "shipping")
    }
  }, [])

  React.useEffect(() => {
    const fn = async () => {
      const options = await shippingOptions.refetch()

      if (options?.data?.shipping_options?.length) {
        const packShop = options.data.shipping_options.find(
          (s) => s.data && s.data.require_drop_point
        )

        if (packShop) {
          loadParcelShops(packShop)
        }
      }
    }

    if (cart?.id && typeof cart?.total !== "undefined") {
      fn()
    }
  }, [cart?.total])

  const isFreeShippingApplied = cart.discounts?.some(
    (i) => i.rule.type === "free_shipping"
  )
  React.useEffect(() => {
    const dropPointId = cart?.shipping_methods?.[0]?.data?.drop_point_id
    if (dropPointId && parcelShopOptions?.options?.length > 0) {
      handleParcelShopSelected(dropPointId)
    }
  }, [parcelShopOptions?.options])

  React.useEffect(() => {
    shippingOptions.refetch()
  }, [isAboveFreeShippingLimit])

  const sortedShippingOptions = React.useMemo(() => {
    if (
      shippingOptions?.isLoading ||
      !shippingOptions.shipping_options?.length
    ) {
      return []
    }
    const excludeDDP =
      cart?.region?.name === "United States" && !hasItemAboveLimit(cart)
    const onlyDDP =
      cart?.region?.name === "United States" && hasItemAboveLimit(cart)
    return sortShippingOptions(
      getNonGiftcardShippingMethods(shippingOptions, excludeDDP, onlyDDP)
    )
  }, [shippingOptions?.isLoading, shippingOptions.shipping_options, cart])

  return (
    <Form
      schema={
        isOnlyGiftCardShippingMethods ? giftCardShippingSchema : shippingSchema
      }
      onSubmit={async (values) => {
        const selectedOption = shippingOptions?.shipping_options?.find(
          (option) => option.id === values["shipping-method"]
        )

        if (isOnlyGiftCardShippingMethods && !selectedOption) {
          trackCheckoutStepCompleted(cart, 2)
          nextStep()
        }

        if (selectedOption) {
          // if selected option is DDP and split order is selected, then throw form error
          if (
            selectedOption.metadata?.is_ddp === "true" &&
            !hasItemAboveLimit(cart)
          ) {
            return
          }
          let data = selectedOption.data
          if (
            selectedOption.data.require_drop_point &&
            parcelShopOptions?.data
          ) {
            if (!parcelShopOptions?.data?.drop_point_id) {
              setParcelWidgetHasError(true)
              return
            }
            data = {
              ...data,
              ...parcelShopOptions.data,
            }
          }

          await addShippingMethod?.mutateAsync(
            {
              option_id: selectedOption.id,
              data,
            },
            {
              onSuccess: async ({ cart }) => {
                if (values["split-order"] === "1") {
                  await updateCart.mutateAsync({
                    context: {
                      ...cart.context,
                      split_order: true,
                    },
                  })
                } else if (values["split-order"] === "2") {
                  await updateCart.mutateAsync({
                    context: {
                      ...cart.context,
                      split_order: false,
                    },
                  })
                }

                trackCheckoutStepCompleted(cart, 2)
                nextStep()
              },
            }
          )
        }
      }}
    >
      {({ resetField, setValue }) => (
        <>
          {canSplitOrder(cart) && (
            <>
              <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
                Avoid extra tax fees
              </Paragraph>
              <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
                Your order value is currently above 800 USD. We advise you to
                split your order, to avoid spending extra on tax fees.
              </Paragraph>
              <RadioField
                name="split-order"
                flexDirection="row"
                defaultValue={
                  cart.context?.split_order
                    ? "1"
                    : typeof cart.context?.split_order === "undefined"
                    ? "1"
                    : "2"
                }
                data={[
                  {
                    label: "Yes, split my order",
                    value: "1",
                  },
                  {
                    label: "No, keep it as one order",
                    value: "2",
                  },
                ]}
                sx={{ marginBlockEnd: 13 }}
              />
            </>
          )}
          <Flex sx={{ flexDirection: "column", gap: 13 }}>
            {hasCartGiftcard(cart) && (
              <Box>
                <Box
                  sx={{
                    flex: 1,
                    color: "primary",
                    border: "1px solid",
                    borderColor: "grayscale.600",
                    cursor: "pointer",
                    transition: "color .2s, border-color .2s",
                    paddingBlock: 3,
                    paddingInline: 4,
                  }}
                  onClick={() => setHasGiftcardFieldError(true)}
                >
                  <Flex>
                    <RadioInput value="value" checked />
                    <RadioIndicator
                      isChecked
                      size="sm"
                      sx={{
                        borderColor: "primary",
                        marginBlockStart: 1,
                        marginInlineEnd: 2,
                      }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Flex sx={{ justifyContent: "space-between" }}>
                        <Paragraph
                          sx={{ fontSize: "sm", transition: "color .2s" }}
                        >
                          Send gift card to {cart?.email}
                        </Paragraph>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
                {hasGiftcardFieldError && (
                  <Paragraph
                    sx={{ color: "red", fontSize: "sm", marginBlockStart: 1 }}
                  >
                    Sending via email is the only option for the gift card.
                  </Paragraph>
                )}
              </Box>
            )}
            {cart?.items?.some((i) => i.is_giftcard === false) && (
              <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
                Select shipping method
              </Paragraph>
            )}
          </Flex>
          {!isOnlyGiftCardShippingMethods &&
          sortedShippingOptions?.length > 0 ? (
            <RadioField
              name="shipping-method"
              errorMessageLabel="Please select the shipping method"
              defaultValue={
                sortedShippingOptions?.[0]?.data?.require_drop_point &&
                !parcelShopOptions.options?.length
                  ? sortedShippingOptions?.[1]?.id
                  : sortedShippingOptions?.[0]?.id
              }
              data={
                sortedShippingOptions?.map(
                  (shippingOption: ShippingOptionWithPrice) => {
                    return {
                      label: shippingOption.name,
                      sideContent: (
                        <Paragraph sx={{ alignSelf: "center", fontSize: "sm" }}>
                          <Text
                            sx={{
                              textDecoration:
                                isFreeShippingApplied &&
                                cart.total < freeShippingLimitThreshold &&
                                "line-through",
                            }}
                          >
                            {shippingOption.price_incl_tax === 0
                              ? "Free"
                              : formatMoneyAmount({
                                  amount: shippingOption.price_incl_tax ?? 0,
                                  currencyCode: cart?.region?.currency_code,
                                })}
                          </Text>
                          {isFreeShippingApplied &&
                            cart.total < freeShippingLimitThreshold && (
                              <Text sx={{ marginInlineStart: 2 }}>Free</Text>
                            )}
                        </Paragraph>
                      ),
                      collapseContent: shippingOption?.data
                        ?.require_drop_point ? (
                        <ParcelWidget
                          loading={parcelShopOptions.loading}
                          options={parcelShopOptions.options}
                          onParcelShopSelected={(value) =>
                            handleParcelShopSelected(value)
                          }
                          onSearch={(v) => loadParcelShops(shippingOption, v)}
                          hasError={parcelWidgetHasError}
                          value={
                            cart?.shipping_methods?.[0]?.data?.drop_point_id
                          }
                        />
                      ) : null,
                      value: shippingOption.id,
                      bottomContent: shippingOption?.metadata
                        ?.lower_carbon_emission ? (
                        <CarbonEmissionTag
                          sx={{ color: "primary", marginInlineStart: 5 }}
                        >
                          <Paragraph>Lower carbon emissions</Paragraph>
                        </CarbonEmissionTag>
                      ) : null,
                    }
                  }
                ) ?? []
              }
            />
          ) : null}
          {sortedShippingOptions?.some(
            (option) => option?.metadata?.lower_carbon_emission
          ) ? (
            <CarbonEmissionCollapse
              label="This delivery method can help to reduce carbon emissions"
              sx={{ marginBlockStart: 9 }}
            >
              {sortedShippingOptions.find(
                (option) =>
                  option?.metadata?.lower_carbon_emission &&
                  option.name?.toLowerCase()?.includes("parcel")
              )?.id ? (
                <Paragraph sx={{ fontSize: "sm" }}>
                  By collecting your order from a parcel shop or pick-up point
                  by bike or on foot, you can help reduce the carbon emissions
                  of your parcel delivery when compared to a home delivery.
                </Paragraph>
              ) : null}
              {sortedShippingOptions.find(
                (option) =>
                  option?.metadata?.lower_carbon_emission &&
                  !option.name?.toLowerCase()?.includes("parcel") &&
                  !sortedShippingOptions.find((i) =>
                    i.name.toLowerCase().includes("parcel")
                  )
              )?.id ? (
                <Paragraph sx={{ fontSize: "sm" }}>
                  Choosing standard delivery over express can reduce the carbon
                  emissions of your order, as slower delivery times can allow
                  for better consolidation of orders, e.g. a fuller van or
                  plane, and more efficient delivery routes. 
                </Paragraph>
              ) : null}
            </CarbonEmissionCollapse>
          ) : null}
          {!isInEditable ? <GiftWrapping /> : null}
          <Flex
            sx={{
              flexDirection: ["column", "row"],
              gap: [4, 6],
              marginBlockStart: isInEditable ? 6 : 9,
            }}
          >
            <Button
              onClick={previousStep}
              variant="secondary"
              sx={{ flex: [null, 1], order: [2, "unset"] }}
            >
              {previousStepLabel ? previousStepLabel : "Back"}
            </Button>
            <SubmitButton
              sx={{ flex: [null, 1] }}
              isLoading={
                addShippingMethod?.isLoading ||
                parcelShopOptions?.loading ||
                updateCart.isLoading
              }
            >
              {nextStepLabel ? nextStepLabel : "Next"}
            </SubmitButton>
          </Flex>
        </>
      )}
    </Form>
  )
}

export default Shipping
