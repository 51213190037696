import { Cart } from "@medusajs/medusa"
import { normalizeAmount } from "./prices"

const DOWN_DUVET_SKUS = ["DN-SS", "DN-SG", "DN-AS", "DN-WS"]
const SATEEN_SLEEPWEAR_ID = "pcat_01J32KNJW9NHGQRRCE4A0ZQ00R"
const SATEEN_BEDDING_ID = "pcat_01J3Z2QTPGYARYXFMGQAQJ3YVJ"

export const canSplitOrder = (cart: Readonly<Cart>) => {
  if (cart?.shipping_address?.country_code?.toLowerCase() !== "us") {
    return false
  }

  if (
    cart?.items.some(
      (item) =>
        (normalizeAmount(cart?.region?.currency_code, item.unit_price) || 0) >
        800
    ) ||
    cart.discounts?.some((discount) => discount?.rule?.allocation === "item")
  ) {
    return false
  }

  if (
    (normalizeAmount(
      cart?.region?.currency_code,
      cart.total + cart.discount_total
    ) || 0) <= 800
  ) {
    return false
  }

  // Check if cart has down duvet
  const downDuvetItems = cart?.items.find((item) =>
    DOWN_DUVET_SKUS.some((id) => item.variant.sku?.startsWith(id))
  )

  if (downDuvetItems?.id) {
    return false
  }

  // Check if cart has any of the sateen products
  if (
    cart?.items.some(
      (item) =>
        item.variant?.product?.primary_category_id === SATEEN_SLEEPWEAR_ID ||
        item.variant?.product?.primary_category_id === SATEEN_BEDDING_ID
    )
  ) {
    return false
  }

  return true
}

export const hasItemAboveLimit = (cart: Readonly<Cart>) => {
  if (
    cart?.items.some(
      (item) =>
        (normalizeAmount(cart?.region?.currency_code, item.unit_price) || 0) >
        800
    ) ||
    cart.discounts?.some((discount) => discount?.rule?.allocation === "item")
  ) {
    return true
  }
  return false
}
