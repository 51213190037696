// External packages
import * as React from "react"
import { FlexProps, Flex } from "theme-ui"

export const CarbonEmissionTag: React.FC<FlexProps> = ({
  children,
  sx,
  ...rest
}) => (
  <Flex
    {...rest}
    sx={{
      ...sx,
      fontSize: "xs",
      gap: 2,
      "&::after": {
        content: "''",
        display: "block",
        width: 2,
        height: 2,
        flexShrink: 0,
        backgroundColor: "grayscale.400",
        borderRadius: 9999,
        position: "relative",
        top: "0.3125rem",
      },
    }}
  >
    {children}
  </Flex>
)
