import * as React from "react"
import { useController } from "react-hook-form"
import { useStore } from "../../../context/NewStoreContext"

import { RadioCardGroup, RadioCardGroupProps } from "../ui/RadioCardGroup"

export interface PaymentFieldProps
  extends Omit<RadioCardGroupProps, "onValueChange"> {
  name: string
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  setClientSecret: React.Dispatch<React.SetStateAction<string>>
}

const PaymentField: React.FC<PaymentFieldProps> = ({
  name,
  setSelectedOption,
  setClientSecret,
  ...props
}) => {
  const store = useStore()
  const { field, formState, fieldState } = useController({ name })

  return (
    <RadioCardGroup
      {...props}
      data={props.data.filter((i) => !i.hide)}
      defaultValue={field.value}
      onValueChange={async (value) => {
        field.onChange(value)
        setSelectedOption(value)

        const res = await store.pay.mutateAsync({
          provider_id: value,
        })

        if (
          value === "stripe" &&
          res?.cart?.payment_session?.data?.client_secret
        ) {
          setClientSecret(res.cart.payment_session.data.client_secret as string)
        }
      }}
      hasError={
        (formState.isDirty || formState.isSubmitted) && !!fieldState.error
      }
      errorMessage={"Please select a payment method"}
    />
  )
}

export default PaymentField
