import * as React from "react"
import { useController } from "react-hook-form"

import { Checkbox, CheckboxProps } from "../ui/Checkbox"

export interface CheckboxFieldProps extends CheckboxProps {
  name: string
  errorMessageLabel?: string
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  errorMessageLabel,
  ...props
}) => {
  const { field, formState, fieldState } = useController({ name })

  React.useEffect(() => {
    if (props.defaultChecked) {
      field.onChange(props.defaultChecked)
    }
  }, [props.defaultChecked])

  return (
    <Checkbox
      {...props}
      onChange={() => {
        field.onChange(!field.value)
      }}
      defaultChecked={props.defaultChecked}
      errorMessage={
        formState.isSubmitted && !field.value
          ? errorMessageLabel || fieldState.error?.message
          : undefined
      }
    />
  )
}

export default CheckboxField
