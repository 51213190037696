import * as React from "react"
import { useController } from "react-hook-form"
import { useStore } from "../../../context/NewStoreContext"

import { Input, InputProps } from "../ui/Input"
import { Dropdown } from "../ui/Dropdown"
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete"
import { Suggestion } from "use-places-autocomplete"
import { Suggestion as StrippedSuggestion } from "../checkout/Details"

export interface TextFieldProps extends Omit<InputProps, "onChange" | "value"> {
  name: string
  customErrorMessage?: string
  isGiftCardOnly?: boolean
  onSuggestionChange?: (suggestion: StrippedSuggestion) => void
  checkLatin?: boolean
}

const PlacesField: React.FC<TextFieldProps> = ({
  name,
  customErrorMessage,
  onSuggestionChange,
  onBlur,
  isGiftCardOnly = false,
  checkLatin = false,
  ...props
}) => {
  const store = useStore()
  const { field, fieldState, formState } = useController({ name })
  const [focus, setFocus] = React.useState(false)

  const placesApi = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: isGiftCardOnly
          ? store?.cart?.billing_address?.country_code
          : store?.cart?.shipping_address?.country_code,
      },
    },
    debounce: 300,
  })

  const {
    ready,
    setValue,
    suggestions: { status, data },
  } = placesApi

  React.useEffect(() => {
    if (field.value === "") {
      placesApi.clearSuggestions()
    }
    if (ready && field.value) {
      setValue(field.value)
    }
  }, [ready, field.value, setValue])

  const handleSuggestionClick = async (place: Suggestion) => {
    const streetAddress = place.structured_formatting.main_text

    const [geocode] = await getGeocode({ placeId: place.place_id })

    const city = geocode.address_components.find((comp) => {
      return comp.types.includes("locality")
    })

    const zip = geocode.address_components.find((comp) => {
      return comp.types.includes("postal_code")
    })

    const province = geocode.address_components.find((comp) => {
      return comp.types.includes("administrative_area_level_1")
    })

    const options = {
      postal_code: "",
      city: "",
      province: "",
    }

    if (zip) {
      options.postal_code = zip.long_name
    }

    if (streetAddress) {
      field.onChange(streetAddress)
    }

    if (city) {
      options.city = city.long_name
    }

    if (
      province &&
      (store?.cart?.shipping_address?.country_code === "us" ||
        store?.cart?.shipping_address?.country_code === "ca")
    ) {
      options.province = province.short_name
    }

    store?.updateCart?.mutate({
      shipping_address: {
        address_1: streetAddress,
        postal_code: options.postal_code,
        city: options.city,
        province: options.province,
      },
    })

    onSuggestionChange(options)
  }

  let subLabel

  if ((formState.isDirty || formState.isSubmitted) && !!fieldState.error) {
    subLabel = customErrorMessage
      ? customErrorMessage
      : fieldState.error?.message
  }

  if (checkLatin) {
    const regex = /^[\x00-ʯ]*$/
    if (!regex.test(field.value)) {
      subLabel = "Please enter only Latin letters"
    }
  }

  return (
    <>
      <Input
        onChange={(text) => field.onChange(text)}
        onBlur={(e) => {
          setTimeout(() => setFocus(false), 200)
          field.onBlur
          onBlur && onBlur(e)
        }}
        value={field.value}
        onFocus={() => {
          setFocus(true)
        }}
        hasError={
          ((formState.isDirty || formState.isSubmitted) &&
            !!fieldState.error) ||
          subLabel
        }
        subLabel={subLabel}
        sx={{
          mb: 0,
        }}
        {...props}
      />
      {status === "OK" &&
        data?.length &&
        focus &&
        store?.cart?.shipping_address?.country_code !== "kr" &&
        store?.cart?.shipping_address?.country_code !== "jp" &&
        store?.cart?.shipping_address?.country_code !== "cn" && (
          <Dropdown
            onValueChange={(value) =>
              handleSuggestionClick(
                data.find((suggestion) => suggestion.place_id === value)
              )
            }
            data={data.map((suggestion) => ({
              label: suggestion.description,
              value: suggestion.place_id,
            }))}
            defaultOpen={true}
          />
        )}
    </>
  )
}

export default PlacesField
