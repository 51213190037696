import * as React from "react"
import { useController } from "react-hook-form"

import { RadioCardGroup, RadioCardGroupProps } from "../ui/RadioCardGroup"

export interface RadioFieldProps
  extends Omit<RadioCardGroupProps, "onValueChange"> {
  name: string
  errorMessageLabel?: string
}

const RadioField: React.FC<RadioFieldProps> = ({
  name,
  errorMessageLabel,
  ...props
}) => {
  const { field, formState, fieldState } = useController({ name })

  React.useEffect(() => {
    if (props.defaultValue) {
      field.onChange(props.defaultValue)
    }
  }, [props.defaultValue])

  return (
    <RadioCardGroup
      {...props}
      data={props.data.filter((i) => !i.hide)}
      defaultValue={props.defaultValue}
      onValueChange={(value) => field.onChange(value)}
      hasError={
        (formState.isDirty || formState.isSubmitted) && !!fieldState.error
      }
      errorMessage={errorMessageLabel}
    />
  )
}

export default RadioField
