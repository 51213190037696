import * as React from "react"
import { navigate } from "gatsby-link"
import { Box, Flex, Paragraph } from "theme-ui"
import { useStore } from "../../../context/NewStoreContext"
import Form from "../form/Form"
import { z } from "zod"

import { Collapse } from "../ui/Collapse"
import { Button } from "../Button"
import { Link } from "../Link"

import Details from "./Details"
import Shipping from "./Shipping"

import SubmitButton from "../form/SubmitButton"
import TextField from "../form/TextField"

import CheckboxField from "../form/CheckboxField"
import CountrySelectField from "../form/CountrySelectField"

import { formatMoneyAmount } from "../../../utils/prices"
import {
  trackCheckoutStepViewed,
  trackCheckoutStepCompleted,
} from "../../../services/analytics"
import { StorePostCartsCartReq, Cart as MedusaCart } from "@medusajs/medusa"
import { CheckoutStepAction } from "./Checkout"

const reviewSchema = z
  .object({
    "billing-address": z.boolean(),
    "billing-address-first-name": z.string().optional(),
    "billing-address-last-name": z.string().optional(),
    "billing-address-1": z.string().optional(),
    "billing-address-2": z.string().optional(),
    "billing-address-postal-code": z.string().optional(),
    "billing-address-city": z.string().optional(),
    "billing-address-phone": z.string().optional(),
    "billing-address-country": z.string().optional(),
    "terms-and-conditions": z.boolean(),
  })
  .refine(
    (data) => {
      if (!data["billing-address"]) {
        return (
          data["billing-address-first-name"] &&
          data["billing-address-last-name"] &&
          data["billing-address-1"] &&
          data["billing-address-postal-code"] &&
          data["billing-address-city"] &&
          data["billing-address-phone"] &&
          data["billing-address-country"]
        )
      }

      return true
    },
    {
      message: "Please fill out the billing address fields",
    }
  )

const ReviewNoPayment: React.FC<CheckoutStepAction> = ({
  previousStep,
  setClientSecret,
  isSwap,
}) => {
  const store = useStore()
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [reviewFieldsets, setReviewFieldsets] = React.useState({
    details: {
      isEditable:
        !store?.cart?.shipping_address?.address_1 ||
        !store?.cart?.shipping_address?.city ||
        !store?.cart?.shipping_address?.postal_code,
    },
    shipping: {
      isEditable: !store.cart?.shipping_methods || isSwap,
    },
  })

  React.useEffect(() => {
    if (store?.cart?.id && !isSwap) {
      trackCheckoutStepViewed(store?.cart, 3, "payment")
    }
  }, [])

  const handlePaymentCompleted = (
    updatedCart: Omit<MedusaCart, "refundable_amount" | "refunded_total">
  ) => {
    if (isSwap) {
      navigate(`/swaps/done?sct=${store.cart?.id}`)
    } else {
      trackCheckoutStepCompleted(store?.cart, 3)
      navigate(`/checkout/payment?uct=${updatedCart?.id ? updatedCart.id : ""}`)
    }
  }

  let combinedAddress = store?.cart?.shipping_address?.address_2
    ? `${store?.cart?.shipping_address?.address_1}, ${store?.cart?.shipping_address?.address_2}`
    : store?.cart?.shipping_address?.address_1

  return (
    <Form
      schema={reviewSchema}
      onSubmit={async (values) => {
        let updatedCart = store?.cart

        if (!values["billing-address"]) {
          const update: StorePostCartsCartReq = {
            billing_address: {
              first_name: values["billing-address-first-name"],
              last_name: values["billing-address-last-name"],
              address_1: values["billing-address-1"],
              address_2: values["billing-address-2"],
              postal_code: values["billing-address-postal-code"],
              city: values["billing-address-city"],
              phone: values["billing-address-phone"],
              country_code: values["billing-address-country"],
            },
          }

          const res = await store?.updateCart?.mutateAsync(update)

          if (res?.cart) {
            updatedCart = res.cart
          }
        }
        handlePaymentCompleted(store?.cart)
      }}
      defaultValues={{
        "billing-address": true,
      }}
    >
      <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>Details</Paragraph>
      <Box
        sx={{
          color: "grayscale.600",
          position: "relative",
          fontSize: "sm",
          border: "1px solid",
          borderColor: "grayscale.300",
          paddingBlock: 5,
          paddingInlineStart: 5,
          paddingInlineEnd: reviewFieldsets.details.isEditable ? 5 : 7,
          marginBlockEnd: 9,
        }}
      >
        {reviewFieldsets.details.isEditable ? (
          <Details
            previousStep={() => {
              setReviewFieldsets({
                ...reviewFieldsets,
                details: {
                  isEditable: false,
                },
              })
            }}
            previousStepLabel="Cancel"
            nextStep={() =>
              setReviewFieldsets({
                ...reviewFieldsets,
                details: {
                  isEditable: false,
                },
              })
            }
            nextStepLabel="Confirm"
            isInEditable
          />
        ) : (
          <>
            <Paragraph sx={{ marginBlockEnd: 2 }}>
              {store?.cart?.email}
            </Paragraph>
            <Paragraph
              sx={{ marginBlockEnd: 2 }}
            >{`${store?.cart?.shipping_address?.first_name} ${store?.cart?.shipping_address?.last_name}`}</Paragraph>
            <Paragraph sx={{ marginBlockEnd: 2 }}>
              {`${combinedAddress}, ${
                store?.cart?.shipping_address?.postal_code
              } ${store?.cart?.shipping_address?.city}, ${
                store?.cart?.region?.countries?.find(
                  (country) =>
                    country.iso_2 ===
                    store?.cart?.shipping_address?.country_code
                )?.display_name
              }`}
            </Paragraph>
            <Paragraph>{store?.cart?.shipping_address?.phone}</Paragraph>
            <Link
              onClick={() => {
                reviewFieldsets.shipping.isEditable === false
                setReviewFieldsets({
                  ...reviewFieldsets,
                  details: {
                    isEditable: true,
                  },
                })
              }}
              sx={{
                color: "grayscale.600",
                position: "absolute",
                top: 0,
                right: 5,
                fontSize: "xs",
                backgroundColor: "grayscale.white",
                transform: "translateY(-50%)",
                paddingInlineEnd: 2,
                paddingInlineStart: 2,
              }}
            >
              Edit
            </Link>
          </>
        )}
      </Box>
      {!(
        store?.cart?.shipping_methods?.[0]?.shipping_option?.name ===
        "Digital Copy"
      ) && (
        <>
          <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
            Shipping method
          </Paragraph>
          <Box
            sx={{
              color: "grayscale.600",
              position: "relative",
              fontSize: "sm",
              border: "1px solid",
              borderColor: "grayscale.300",
              paddingBlock: 5,
              paddingInlineStart: 5,
              paddingInlineEnd: reviewFieldsets.shipping.isEditable ? 5 : 7,
              marginBlockEnd: 9,
            }}
          >
            {reviewFieldsets.shipping.isEditable ||
            !store?.cart?.shipping_methods?.length ? (
              <Shipping
                previousStep={() => {
                  setReviewFieldsets({
                    ...reviewFieldsets,
                    shipping: {
                      isEditable: false,
                    },
                  })
                }}
                previousStepLabel="Cancel"
                nextStep={() =>
                  setReviewFieldsets({
                    ...reviewFieldsets,
                    shipping: {
                      isEditable: false,
                    },
                  })
                }
                nextStepLabel="Confirm"
                isInEditable
              />
            ) : (
              <>
                <Flex sx={{ justifyContent: "space-between" }}>
                  <Box>
                    <Paragraph>
                      {
                        store?.cart?.shipping_methods?.[0]?.shipping_option
                          ?.name
                      }
                    </Paragraph>
                  </Box>
                  <Paragraph sx={{ whiteSpace: "nowrap" }}>
                    {store?.cart?.shipping_methods?.[0]?.total === 0
                      ? "Free"
                      : formatMoneyAmount({
                          amount:
                            store?.cart?.region?.name === "United States"
                              ? store?.cart?.shipping_methods?.[0]?.subtotal
                              : store?.cart?.shipping_methods?.[0]?.total,
                          currencyCode: store?.cart?.region?.currency_code,
                        })}
                  </Paragraph>
                </Flex>
                <Link
                  onClick={() => {
                    reviewFieldsets.details.isEditable === false &&
                      setReviewFieldsets({
                        ...reviewFieldsets,
                        shipping: {
                          isEditable: true,
                        },
                      })
                  }}
                  sx={{
                    color: "grayscale.600",
                    position: "absolute",
                    top: 0,
                    right: 5,
                    fontSize: "xs",
                    backgroundColor: "grayscale.white",
                    transform: "translateY(-50%)",
                    paddingInlineStart: 2,
                    paddingInlineEnd: 2,
                  }}
                >
                  Edit
                </Link>
              </>
            )}
          </Box>
        </>
      )}
      <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
        Select payment method
      </Paragraph>
      <CheckboxField
        name="billing-address"
        label="Use the same billing address as shipping address"
        defaultChecked={true}
        subContentVisibilityReversed={true}
        subContent={
          <Box sx={{ paddingBlockStart: 4 }}>
            <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
              Billing address
            </Paragraph>
            <Box sx={{ display: [null, "flex"], gap: 6 }}>
              <TextField
                name="billing-address-first-name"
                placeholder="First name"
                hasFloatingLabel
                sx={{ flex: 1, marginBlockEnd: 6 }}
              />
              <TextField
                name="billing-address-last-name"
                placeholder="Last name"
                hasFloatingLabel
                sx={{ flex: 1, marginBlockEnd: 6 }}
              />
            </Box>
            <CountrySelectField
              name="billing-address-country"
              placeholder="Country"
              hasFloatingLabel
              shouldChangeCountry={false}
              sx={{ marginBlockEnd: 6 }}
            />
            <TextField
              name="billing-address-1"
              placeholder="Address"
              hasFloatingLabel
              sx={{ marginBlockEnd: 2 }}
            />
            <Collapse
              labelWhenOpened="Show less"
              labelWhenClosed="Company, apartment, floor, etc. (optional)"
              labelPosition="end"
              sx={{ marginBlockEnd: 6 }}
            >
              <Box sx={{ paddingBlockStart: 4, paddingBlockEnd: 2 }}>
                <TextField
                  name="billing-address-2"
                  placeholder="Apartment, unit, company etc."
                  hasFloatingLabel
                />
              </Box>
            </Collapse>
            <Flex sx={{ marginBlockEnd: 6, gap: 6 }}>
              <TextField
                name="billing-address-postal-code"
                placeholder="Postal code"
                hasFloatingLabel
                sx={{ width: "35%" }}
              />
              <TextField
                name="billing-address-city"
                placeholder="City"
                hasFloatingLabel
                sx={{ flex: 1 }}
              />
            </Flex>
            <TextField
              name="billing-address-phone"
              placeholder="Phone number"
              type="tel"
              hasFloatingLabel
            />
          </Box>
        }
        sx={{ marginBlockEnd: 4 }}
      />
      <CheckboxField
        name="terms-and-conditions"
        defaultChecked={true}
        label="I confirm that I have read and understood the Terms and conditions and the Privacy policy"
        errorMessageLabel="You must agree to the Terms and conditions and Privacy policy"
      />
      {errorMessage && (
        <Paragraph
          sx={{
            fontSize: "xs",
            color: "red",
            marginBlockStart: 9,
            marginBlockEnd: -5,
          }}
        >
          We couldn't complete your payment. Please try again.
        </Paragraph>
      )}
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          gap: [4, 6],
          marginBlockStart: 9,
        }}
      >
        <SubmitButton
          isLoading={store?.pay?.isLoading || store?.updateCart?.isLoading}
          isVisuallyDisabled={
            reviewFieldsets.details.isEditable ||
            reviewFieldsets.shipping.isEditable
          }
          disabled={
            reviewFieldsets.details.isEditable ||
            reviewFieldsets.shipping.isEditable
          }
          sx={{ flex: [null, 1], order: [null, 2] }}
        >
          Place order
        </SubmitButton>
        <Button
          variant="secondary"
          onClick={() => {
            if (store?.pay?.isLoading || store?.updateCart?.isLoading) {
              return
            }

            previousStep()
          }}
          sx={{ flex: [null, 1] }}
        >
          Back
        </Button>
      </Flex>
    </Form>
  )
}

export default ReviewNoPayment
