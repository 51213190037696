import * as React from "react"
import * as RHF from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { isAxiosError } from "axios"

export interface UseFormProps<S extends z.ZodTypeAny, TContext = any>
  extends Omit<RHF.UseFormProps<z.infer<S>, TContext>, "resolver"> {
  schema: S
  onSubmit: (
    values: z.infer<S>,
    form: RHF.UseFormReturn<z.infer<S>, TContext>
  ) => Promise<void>
  defaultValues?: RHF.UseFormProps<z.infer<S>>["defaultValues"] &
    Record<string, any>
}

export const useForm = <S extends z.ZodTypeAny>({
  schema,
  onSubmit,
  defaultValues,
  ...props
}: UseFormProps<S>) => {
  const form = RHF.useForm<z.infer<S>>({
    ...props,
    defaultValues,
    resolver: zodResolver(schema),
  })

  const onSubmitHandler = React.useCallback(async () => {
    // TODO: handle submit handler errors
    await form.handleSubmit(async (values) => {
      try {
        await onSubmit(values, form)
      } catch (error) {
        if (isAxiosError(error)) {
          if (!error.response) {
            console.error(
              "Network error. We were not able to contact server. Please, check your internet connection."
            )
            return
          }

          if (error.response.data) {
            console.log(JSON.stringify(error.response.data, null, 2))
          }

          if (error.response.status >= 500) {
            console.error("Server was unable to process your request.")
            return
          }

          if (error.response.status === 401) {
            console.error("You are not authorized to perform this action.")
            return
          }

          if (error.response.status === 403) {
            console.error("You are forbidden to perform this action.")
            return
          }

          if (error.response.status === 404) {
            console.error("Resource was not found.")
            return
          }
        }

        if (error instanceof Error && error.message) {
          console.error(error.message)
          return
        }

        console.error(
          "Something went wrong. If this error persists please contact administrator."
        )
        return
      }
    })()
  }, [onSubmit, form])

  return { ...form, onSubmit: onSubmitHandler }
}

const formSubmitContext = React.createContext<{
  onSubmit: () => Promise<void>
}>({
  onSubmit: () => Promise.resolve(),
})

export const useFormSubmit = () => {
  return React.useContext(formSubmitContext).onSubmit
}

export interface FormProps<S extends z.ZodTypeAny> extends UseFormProps<S> {
  children:
    | React.ReactNode
    | ((
        form: RHF.UseFormReturn<z.infer<S>> & {
          onSubmit: () => Promise<void>
        }
      ) => React.ReactNode)
}

const Form = <S extends z.ZodTypeAny>({ children, ...props }: FormProps<S>) => {
  const { onSubmit, ...form } = useForm(props)

  return (
    <RHF.FormProvider {...form}>
      <formSubmitContext.Provider value={{ onSubmit }}>
        {typeof children === "function"
          ? children({ ...form, onSubmit })
          : children}
      </formSubmitContext.Provider>
    </RHF.FormProvider>
  )
}

export default Form
