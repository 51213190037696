import * as React from "react"
import { Collapse as ReactCollapse } from "react-collapse"
import {
  Box,
  Flex,
  FlexProps,
  Paragraph,
  Text,
  ThemeUIStyleObject,
} from "theme-ui"

export interface CollapseProps extends FlexProps {
  variant?: "ghost" | "solid"
  label?: string
  labelWhenOpened?: string
  labelWhenClosed?: string
  labelPosition?: "start" | "end"
  labelStyle?: ThemeUIStyleObject
  iconPosition?: "start" | "end"
  isFullWidth?: boolean
  defaultOpened?: boolean
}

export const Collapse: React.FC<CollapseProps> = ({
  children,
  variant = "ghost",
  label,
  labelWhenOpened,
  labelWhenClosed,
  labelPosition = "start",
  labelStyle,
  iconPosition = "start",
  isFullWidth = false,
  defaultOpened = false,
  sx,
  ...flexProps
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpened)

  return (
    <Flex sx={{ flexDirection: "column", ...sx }} {...flexProps}>
      <Paragraph
        sx={{
          display: "flex",
          justifyContent: isFullWidth && "space-between",
          width: !isFullWidth && "max-content",
          fontSize: "xs",
          color: variant === "solid" && "grayscale.white",
          backgroundColor: variant === "solid" && "grayscale.700",
          cursor: "pointer",
          order: labelPosition === "start" ? 0 : 1,
          paddingBlock: variant === "solid" && 3,
          paddingInline: variant === "solid" && 4,
          ...labelStyle,
        }}
        onClick={() => {
          setIsOpen((val) => !val)
        }}
      >
        <Text
          sx={{
            display: "inline-block",
            order: iconPosition === "start" ? 0 : 1,
            marginInlineEnd: iconPosition === "start" && 2,
            marginInlineStart: iconPosition === "end" && 2,
          }}
        >
          {isOpen ? "–" : "+"}
        </Text>
        {label ? label : isOpen ? labelWhenOpened : labelWhenClosed}
      </Paragraph>
      <Box>
        <ReactCollapse isOpened={isOpen}>{children}</ReactCollapse>
      </Box>
    </Flex>
  )
}
