import * as React from "react"
import { navigate } from "gatsby-link"
import { Box, Flex, Image, Paragraph } from "theme-ui"
import { useStore } from "../../../context/NewStoreContext"
import Form from "../form/Form"
import { z } from "zod"
import {
  PaymentElement,
  CardElement,
  useStripe,
  useElements,
  IdealBankElement,
} from "@stripe/react-stripe-js"
import {
  getCountries,
  getCountryCallingCode,
  CountryCode,
  parsePhoneNumber,
} from "libphonenumber-js"

import { Collapse } from "../ui/Collapse"
import { Button } from "../Button"
import { Link } from "../Link"

import Details from "./Details"
import Shipping from "./Shipping"

import Mastercard from "../../../assets/pay-icons/mastercard.svg"
import Amex from "../../../assets/pay-icons/amex.svg"
import Mobilepay from "../../../assets/pay-icons/mobilepay.svg"
import VISA from "../../../assets/pay-icons/visa.svg"
import JCB from "../../../assets/pay-icons/jcb.svg"

import SubmitButton from "../form/SubmitButton"
import TextField from "../form/TextField"
import PaymentField from "../form/PaymentField"
import CheckboxField from "../form/CheckboxField"
import CountrySelectField from "../form/CountrySelectField"
import SelectField from "../form/SelectField"

import { formatMoneyAmount } from "../../../utils/prices"
import {
  trackCheckoutStepViewed,
  trackCheckoutStepCompleted,
} from "../../../services/analytics"
import { StorePostCartsCartReq, Cart as MedusaCart } from "@medusajs/medusa"
import { CheckoutStepAction } from "./Checkout"
import Medusa from "../../../services/api"
import { hasCartGiftcard } from "../utils/cart/hasCartGiftcard"
import { countriesCodeSelectData } from "./Details"

const URL = process.env.GATSBY_URL || "http://localhost:8000"

const reviewSchema = z
  .object({
    "payment-method": z.string(),
    "billing-address": z.boolean(),
    "billing-address-first-name": z.string().optional(),
    "billing-address-last-name": z.string().optional(),
    "billing-address-1": z.string().optional(),
    "billing-address-2": z.string().optional(),
    "billing-address-postal-code": z.string().optional(),
    "billing-address-city": z.string().optional(),
    "billing-address-country-code": z.string().min(1).optional(),
    "billing-address-phone": z.string().optional(),
    "billing-address-country": z.string().optional(),
    "terms-and-conditions": z.boolean(),
  })
  .refine(
    // TODO: Get validation working
    (data) => {
      if (!data["billing-address"]) {
        return (
          data["billing-address-first-name"] &&
          data["billing-address-last-name"] &&
          data["billing-address-1"] &&
          data["billing-address-postal-code"] &&
          data["billing-address-city"] &&
          data["billing-address-country-code"] &&
          data["billing-address-phone"] &&
          data["billing-address-country"]
        )
      }

      return true
    },
    {
      message: "Please fill out the billing address fields",
    }
  )

const Review: React.FC<CheckoutStepAction> = ({
  previousStep,
  setClientSecret,
  isSwap,
}) => {
  const store = useStore()
  const [paymentProviders, setPaymentProviders] = React.useState<string[]>([])
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [canMakePayment, setCanMakePayment] = React.useState(false)
  const [paymentRequest, setPaymentRequest] = React.useState(undefined)
  const [selectedOption, setSelectedOption] = React.useState()
  const [reviewFieldsets, setReviewFieldsets] = React.useState({
    details: {
      isEditable:
        !store?.cart?.shipping_address.address_1 ||
        !store?.cart?.shipping_address.city ||
        !store?.cart?.shipping_address.postal_code,
    },
    shipping: {
      isEditable: !store.cart?.shipping_methods?.length || isSwap,
    },
  })

  const isOnlyGiftCardShippingMethods = store?.cart?.shipping_methods?.every(
    (sm) =>
      sm.shipping_option.name === "Printed Copy" ||
      sm.shipping_option.name === "Digital Copy"
  )

  React.useEffect(() => {
    if (store?.cart?.id) {
      if (!isSwap) {
        trackCheckoutStepViewed(store?.cart, 3, "payment")
      }

      setPaymentProviders(
        store?.cart?.region?.payment_providers
          ?.map((p) => p.id)
          ?.filter((p) =>
            store?.splitOrderCarts?.length ? p === "stripe" : true
          )
      )
    }
  }, [])

  React.useEffect(() => {
    if (!store?.cart?.shipping_methods?.length) {
      setReviewFieldsets({
        ...reviewFieldsets,
        shipping: {
          isEditable: true,
        },
      })
    }
  }, [store?.cart?.shipping_methods])

  const handlePaymentCompleted = (
    updatedCart: Omit<MedusaCart, "refundable_amount" | "refunded_total">
  ) => {
    if (isSwap) {
      navigate(`/swaps/done?sct=${store.cart?.id}`)
    } else {
      trackCheckoutStepCompleted(store?.cart, 3)
      navigate(`/checkout/payment?uct=${updatedCart?.id ? updatedCart.id : ""}`)
    }
  }

  let combinedAddress = store?.cart?.shipping_address?.address_2
    ? `${store?.cart?.shipping_address?.address_1}, ${store?.cart?.shipping_address?.address_2}`
    : store?.cart?.shipping_address?.address_1

  return (
    <Form
      schema={reviewSchema}
      onSubmit={async (values) => {
        let updatedCart = store?.cart

        const callingCodeCountry = countriesCodeSelectData.find(
          (c) =>
            c.value === values["billing-address-country-code"]?.toUpperCase()
        )?.value

        if (!values["billing-address"]) {
          const update: StorePostCartsCartReq = {
            billing_address: {
              first_name: values["billing-address-first-name"],
              last_name: values["billing-address-last-name"],
              address_1: values["billing-address-1"],
              address_2: values["billing-address-2"],
              postal_code: values["billing-address-postal-code"],
              city: values["billing-address-city"],
              phone: parsePhoneNumber(
                values["billing-address-phone"],
                callingCodeCountry ??
                  (values[
                    "billing-address-country"
                  ].toUpperCase() as CountryCode)
              ).number,
              country_code: values["billing-address-country"],
            },
          }

          const res = await store?.updateCart?.mutateAsync(update)

          if (res?.cart) {
            updatedCart = res.cart
          }
        }

        if (values["payment-method"] === "stripe") {
          if (store?.splitOrderCarts && store?.splitOrderCarts?.length) {
            let completedCarts = []

            for (const splitCart of store?.splitOrderCarts) {
              await stripe
                .confirmCardPayment(
                  splitCart?.payment_session?.data?.client_secret as string,
                  {
                    payment_method: {
                      card: elements.getElement(CardElement),
                      billing_details: {
                        name: `${updatedCart?.billing_address?.first_name} ${updatedCart?.billing_address?.last_name}`,
                        email: updatedCart?.email,
                        phone: updatedCart?.billing_address?.phone,
                        address: {
                          line1: updatedCart?.billing_address?.address_1,
                          line2: updatedCart?.billing_address?.address_2,
                          city: updatedCart?.billing_address?.city,
                          postal_code:
                            updatedCart?.billing_address?.postal_code,
                          country:
                            updatedCart?.billing_address?.country_code.toUpperCase(),
                          state: updatedCart?.billing_address?.province,
                        },
                      },
                    },
                  }
                )
                .then(async ({ error, paymentIntent }) => {
                  if (error) {
                    const pi = error.payment_intent

                    if (
                      (pi && pi.status === "requires_capture") ||
                      (pi && pi.status === "succeeded")
                    ) {
                      let completedCart = await Medusa.cart.completeCart(
                        splitCart?.id
                      )

                      if (completedCart) {
                        completedCarts.push(completedCart.data.data.id)
                      }
                    }

                    setErrorMessage(error.message)
                  }

                  if (
                    paymentIntent &&
                    (paymentIntent.status === "requires_capture" ||
                      paymentIntent.status === "succeeded")
                  ) {
                    let completedCart = await Medusa.cart.completeCart(
                      splitCart?.id
                    )

                    if (completedCart) {
                      completedCarts.push(completedCart.data.data.id)
                    }
                  }

                  return
                })
            }

            if (completedCarts.length) {
              localStorage.removeItem("medusa::cache::splitordercart")
              store?.clearCart()

              navigate(
                `/checkout/done?o=${completedCarts[0]}&splitOrderCart=${store?.cart?.id}`,
                {
                  replace: true,
                }
              )
            } else {
              navigate("/checkout", { replace: true })
            }
          } else {
            const stripeResponse = await stripe
              .confirmPayment({
                elements: elements,
                redirect: "if_required",
                confirmParams: {
                  return_url: isSwap
                    ? `${URL}/swaps/done?sct=${updatedCart?.id}`
                    : `${URL}/checkout/payment?uct=${
                        updatedCart?.id ? updatedCart.id : ""
                      }`,
                  payment_method_data: {
                    billing_details: {
                      name: `${updatedCart?.billing_address?.first_name} ${updatedCart?.billing_address?.last_name}`,
                      email: updatedCart?.email,
                      phone: updatedCart?.billing_address?.phone,
                      address: {
                        line1: updatedCart?.billing_address?.address_1,
                        line2: updatedCart?.billing_address?.address_2,
                        city: updatedCart?.billing_address?.city,
                        postal_code: updatedCart?.billing_address?.postal_code,
                        country:
                          updatedCart?.billing_address?.country_code.toUpperCase(),
                        state: updatedCart?.billing_address?.province,
                      },
                    },
                  },
                },
              })
              .catch((err) => {
                console.log(err)
              })

            if (stripeResponse && stripeResponse?.error) {
              const pi = stripeResponse.error.payment_intent

              if (
                (pi && pi.status === "requires_capture") ||
                (pi && pi.status === "succeeded")
              ) {
                handlePaymentCompleted(store?.cart)
              }

              setErrorMessage(stripeResponse.error.message)
            }

            if (
              stripeResponse &&
              stripeResponse?.paymentIntent &&
              (stripeResponse?.paymentIntent.status === "requires_capture" ||
                stripeResponse?.paymentIntent.status === "succeeded")
            ) {
              handlePaymentCompleted(store?.cart)
            }
          }
        }

        if (values["payment-method"] === "reepay") {
          if (
            store?.cart?.payment_session &&
            store?.cart?.payment_session?.data?.action?.url
          ) {
            window.location.href = store.cart.payment_session.data.action.url
          }
        }

        if (values["payment-method"] === "stripe-giropay") {
          await stripe.confirmGiropayPayment(
            store?.cart?.payment_session?.data?.client_secret as string,
            {
              payment_method: {
                billing_details: {
                  name: `${store?.cart?.shipping_address.first_name} ${store?.cart?.shipping_address.last_name}`,
                },
              },
              return_url: `${URL}/checkout/payment?uct=${
                updatedCart?.id ? updatedCart.id : ""
              }`,
            }
          )
        }

        if (values["payment-method"] === "stripe-bancontact") {
          await stripe.confirmBancontactPayment(
            store?.cart?.payment_session?.data?.client_secret as string,
            {
              payment_method: {
                billing_details: {
                  name: `${store?.cart?.shipping_address.first_name} ${store?.cart?.shipping_address.last_name}`,
                },
              },
              return_url: `${URL}/checkout/payment?uct=${
                updatedCart?.id ? updatedCart.id : ""
              }`,
            }
          )
        }

        if (values["payment-method"] === "stripe-ideal") {
          await stripe.confirmIdealPayment(
            store?.cart?.payment_session?.data?.client_secret as string,
            {
              payment_method: {
                ideal: elements.getElement(IdealBankElement),
                billing_details: {
                  name: `${store?.cart?.shipping_address.first_name} ${store?.cart?.shipping_address.last_name}`,
                },
              },
              return_url: `${URL}/checkout/payment?uct=${
                updatedCart?.id ? updatedCart.id : ""
              }`,
            }
          )
        }
      }}
      defaultValues={{
        "billing-address": true,
        "billing-address-country-code":
          store?.cart?.shipping_address?.country_code?.toUpperCase() ??
          undefined,
      }}
    >
      <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>Details</Paragraph>
      <Box
        sx={{
          color: "grayscale.600",
          position: "relative",
          fontSize: "sm",
          border: "1px solid",
          borderColor: "grayscale.300",
          paddingBlock: 5,
          paddingInlineStart: 5,
          paddingInlineEnd: reviewFieldsets.details.isEditable ? 5 : 7,
          marginBlockEnd: 9,
        }}
      >
        {reviewFieldsets.details.isEditable ? (
          <Details
            isSwap={isSwap}
            previousStep={() => {
              setReviewFieldsets({
                ...reviewFieldsets,
                details: {
                  isEditable: false,
                },
              })
            }}
            previousStepLabel="Cancel"
            nextStep={() =>
              setReviewFieldsets({
                ...reviewFieldsets,
                details: {
                  isEditable: false,
                },
              })
            }
            nextStepLabel="Confirm"
            isInEditable
          />
        ) : (
          <>
            <Paragraph sx={{ marginBlockEnd: 2 }}>
              {store?.cart?.email}
            </Paragraph>
            <Paragraph
              sx={{ marginBlockEnd: 2 }}
            >{`${store?.cart?.shipping_address?.first_name} ${store?.cart?.shipping_address?.last_name}`}</Paragraph>
            <Paragraph sx={{ marginBlockEnd: 2 }}>
              {`${combinedAddress}, ${
                store?.cart?.shipping_address?.postal_code
              } ${store?.cart?.shipping_address?.city}, ${
                store?.cart?.region?.countries?.find(
                  (country) =>
                    country.iso_2 ===
                    store?.cart?.shipping_address?.country_code
                )?.display_name
              }`}
            </Paragraph>
            <Paragraph>{store?.cart?.shipping_address?.phone}</Paragraph>
            <Link
              onClick={() => {
                reviewFieldsets.shipping.isEditable === false
                setReviewFieldsets({
                  ...reviewFieldsets,
                  details: {
                    isEditable: true,
                  },
                })
              }}
              sx={{
                color: "grayscale.600",
                position: "absolute",
                top: 0,
                right: 5,
                fontSize: "xs",
                backgroundColor: "grayscale.white",
                transform: "translateY(-50%)",
                paddingInlineEnd: 2,
                paddingInlineStart: 2,
              }}
            >
              Edit
            </Link>
          </>
        )}
      </Box>
      <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
        Shipping method
      </Paragraph>
      <Box
        sx={{
          color: "grayscale.600",
          position: "relative",
          fontSize: "sm",
          border: "1px solid",
          borderColor: "grayscale.300",
          paddingBlock: 5,
          paddingInlineStart: 5,
          paddingInlineEnd: reviewFieldsets.shipping.isEditable ? 5 : 7,
          marginBlockEnd: 9,
        }}
      >
        {reviewFieldsets.shipping.isEditable ||
        (!store?.cart?.shipping_methods?.length &&
          !isOnlyGiftCardShippingMethods) ? (
          <Shipping
            previousStep={() => {
              setReviewFieldsets({
                ...reviewFieldsets,
                shipping: {
                  isEditable: false,
                },
              })
            }}
            previousStepLabel="Cancel"
            nextStep={() =>
              setReviewFieldsets({
                ...reviewFieldsets,
                shipping: {
                  isEditable: false,
                },
              })
            }
            nextStepLabel="Confirm"
            isInEditable
          />
        ) : (
          <>
            <Flex sx={{ flexDirection: "column", gap: 2 }}>
              {hasCartGiftcard(store?.cart) && (
                <Flex sx={{ justifyContent: "space-between" }}>
                  <Box>
                    <Paragraph>
                      Send gift card to {store?.cart?.email}
                    </Paragraph>
                  </Box>
                  <Paragraph sx={{ whiteSpace: "nowrap" }}>Free</Paragraph>
                </Flex>
              )}
              {store?.cart?.shipping_methods
                ?.filter(
                  (i) =>
                    i.shipping_option.name !== "Printed Copy" &&
                    i.shipping_option.name !== "Digital Copy"
                )
                .map((item) => (
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <Box>
                      <Paragraph>{item?.shipping_option?.name}</Paragraph>
                    </Box>
                    <Paragraph sx={{ whiteSpace: "nowrap" }}>
                      {store?.cart?.shipping_methods?.[0]?.total === 0
                        ? "Free"
                        : formatMoneyAmount({
                            amount:
                              store?.cart?.region?.name === "United States"
                                ? store?.cart?.shipping_methods?.[0]?.subtotal
                                : store?.cart?.shipping_methods?.[0]?.total,
                            currencyCode: store?.cart?.region?.currency_code,
                          })}
                    </Paragraph>
                  </Flex>
                ))}
            </Flex>
            <Link
              onClick={() => {
                reviewFieldsets.details.isEditable === false &&
                  setReviewFieldsets({
                    ...reviewFieldsets,
                    shipping: {
                      isEditable: true,
                    },
                  })
              }}
              sx={{
                color: "grayscale.600",
                position: "absolute",
                top: 0,
                right: 5,
                fontSize: "xs",
                backgroundColor: "grayscale.white",
                transform: "translateY(-50%)",
                paddingInlineStart: 2,
                paddingInlineEnd: 2,
              }}
            >
              Edit
            </Link>
          </>
        )}
      </Box>
      <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
        Select payment method
      </Paragraph>
      <PaymentField
        name="payment-method"
        setSelectedOption={setSelectedOption}
        setClientSecret={setClientSecret}
        data={[
          {
            label: "Payment method",
            hideSideContentOnOpen: true,
            sideContent: (
              <Flex
                sx={{
                  gap: [2, 3],
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Image
                    src={JCB}
                    sx={{
                      display: "block",
                      width: [5, 6],
                      height: 3,
                    }}
                  />
                </Box>
                <Box>
                  <Image
                    src={Mastercard}
                    sx={{
                      display: "block",
                      width: [5, 6],
                      height: 3,
                    }}
                  />
                </Box>
                <Box>
                  <Image
                    src={Amex}
                    sx={{
                      display: "block",
                      width: [5, 6],
                      height: 3,
                    }}
                  />
                </Box>
                <Box>
                  <Image
                    src={VISA}
                    sx={{
                      display: "block",
                      width: [5, 6],
                      height: 3,
                    }}
                  />
                </Box>
              </Flex>
            ),
            collapseContent: (
              <Box sx={{ paddingBlockStart: 6 }}>
                {store?.splitOrderCarts?.length ? (
                  <CardElement />
                ) : (
                  <PaymentElement
                    options={{
                      fields: {
                        billingDetails: "never",
                      },
                      terms: {
                        bancontact: "auto",
                        ideal: "auto",
                        card: "auto",
                      },
                    }}
                  />
                )}
              </Box>
            ),
            value: "stripe",
            hide: !paymentProviders?.includes("stripe"),
          },
          // {
          //   label: "MobilePay",
          //   sideContent: (
          //     <Box>
          //       <Image
          //         src={Mobilepay}
          //         sx={{ display: "block", width: 19, height: 5 }}
          //       />
          //     </Box>
          //   ),
          //   value: "reepay",
          //   hide: !paymentProviders?.includes("reepay"),
          // },
        ]}
        sx={{ marginBlockEnd: 6 }}
      />
      <CheckboxField
        name="billing-address"
        label="Use the same billing address as shipping address"
        defaultChecked={true}
        subContentVisibilityReversed={true}
        subContent={
          <Box sx={{ paddingBlockStart: 4 }}>
            <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
              Billing address
            </Paragraph>
            <Box sx={{ display: [null, "flex"], gap: 6 }}>
              <TextField
                name="billing-address-first-name"
                placeholder="First name"
                hasFloatingLabel
                sx={{ flex: 1, marginBlockEnd: 6 }}
              />
              <TextField
                name="billing-address-last-name"
                placeholder="Last name"
                hasFloatingLabel
                sx={{ flex: 1, marginBlockEnd: 6 }}
              />
            </Box>
            <CountrySelectField
              name="billing-address-country"
              placeholder="Country"
              hasFloatingLabel
              shouldChangeCountry={false}
              sx={{ marginBlockEnd: 6 }}
            />
            <TextField
              name="billing-address-1"
              placeholder="Address"
              hasFloatingLabel
              sx={{ marginBlockEnd: 2 }}
            />
            <Collapse
              labelWhenOpened="Show less"
              labelWhenClosed="Company, apartment, floor, etc. (optional)"
              labelPosition="end"
              sx={{ marginBlockEnd: 6 }}
            >
              <Box sx={{ paddingBlockStart: 4, paddingBlockEnd: 2 }}>
                <TextField
                  name="billing-address-2"
                  placeholder="Apartment, unit, company etc."
                  hasFloatingLabel
                />
              </Box>
            </Collapse>
            <Flex sx={{ marginBlockEnd: 6, gap: 6 }}>
              <TextField
                name="billing-address-postal-code"
                placeholder="Postal code"
                hasFloatingLabel
                sx={{ width: "35%" }}
              />
              <TextField
                name="billing-address-city"
                placeholder="City"
                hasFloatingLabel
                sx={{ flex: 1 }}
              />
            </Flex>
            <Flex sx={{ gap: 6 }}>
              <SelectField
                name="billing-address-country-code"
                placeholder="Country code"
                hasFloatingLabel
                hasFloatingDropdown
                data={countriesCodeSelectData}
                sx={{ width: 30 }}
              />
              <TextField
                name="billing-address-phone"
                placeholder="Phone number"
                type="tel"
                hasFloatingLabel
                sx={{ flexGrow: 1 }}
              />
            </Flex>
          </Box>
        }
        sx={{ marginBlockEnd: 4 }}
      />
      <CheckboxField
        name="terms-and-conditions"
        defaultChecked={true}
        label="I confirm that I have read and understood the Terms and conditions and the Privacy policy"
        errorMessageLabel="You must agree to the Terms and conditions and Privacy policy"
      />
      {errorMessage && (
        <Paragraph
          sx={{
            fontSize: "xs",
            color: "red",
            marginBlockStart: 9,
            marginBlockEnd: -5,
          }}
        >
          We couldn't complete your payment. Please try again.
        </Paragraph>
      )}
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          gap: [4, 6],
          marginBlockStart: 9,
        }}
      >
        <SubmitButton
          isLoading={
            store?.pay?.isLoading ||
            store?.updateCart?.isLoading ||
            paymentRequest?.isShowing()
          }
          isVisuallyDisabled={
            reviewFieldsets.details.isEditable ||
            reviewFieldsets.shipping.isEditable
          }
          disabled={
            reviewFieldsets.details.isEditable ||
            reviewFieldsets.shipping.isEditable
          }
          sx={{ flex: [null, 1], order: [null, 2] }}
        >
          Place order
        </SubmitButton>
        <Button
          variant="secondary"
          onClick={() => {
            if (
              store?.pay?.isLoading ||
              store?.updateCart?.isLoading ||
              paymentRequest?.isShowing()
            ) {
              return
            }

            previousStep()
          }}
          sx={{ flex: [null, 1] }}
        >
          Back
        </Button>
      </Flex>
    </Form>
  )
}

export default Review
