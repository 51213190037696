import * as React from "react"
import { useFormContext } from "react-hook-form"

import { Button, ButtonProps } from "../Button"
import { useFormSubmit } from "./Form"

const SubmitButton: React.FC<ButtonProps> = (props) => {
  const {
    formState: { isSubmitting },
  } = useFormContext()
  const submit = useFormSubmit()

  return (
    <Button
      {...props}
      onClick={(event) => {
        event.preventDefault()

        if (props.isLoading) {
          return
        }

        submit()
        props.onClick?.(event)
      }}
      isLoading={isSubmitting || props.isLoading}
    />
  )
}

export default SubmitButton
