import * as React from "react"
import { Box, BoxProps, BoxOwnProps, Paragraph, Flex, Text } from "theme-ui"

import { Icon } from "./Icon"

interface SelectBoxProps
  extends Omit<React.ComponentPropsWithRef<"select">, "color">,
    BoxOwnProps {}

const SelectBox: React.FC<SelectBoxProps> = ({ sx, ...props }) => (
  <Box
    as="select"
    sx={{
      position: "absolute",
      width: "1px",
      height: "1px",
      border: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      clip: "rect(0px, 0px, 0px, 0px)",
      padding: 0,
      margin: "-1px",
      ...sx,
    }}
    {...(props as unknown as BoxProps)}
  />
)

export interface SelectProps extends SelectBoxProps {
  data: {
    value: string
    subLabel?: string
    label: string
    selectedLabel?: string | undefined
  }[]
  placeholder?: string
  hasFloatingLabel?: boolean
  hasFloatingDropdown?: boolean
  hasError?: boolean
  errorMessage?: string
  onValueChange?: (value: string) => void
}

export const Select: React.FC<SelectProps> = ({
  data,
  placeholder,
  hasFloatingLabel,
  hasFloatingDropdown,
  hasError,
  errorMessage,
  sx,
  value,
  onValueChange,
  children,
  ...selectProps
}) => {
  const [options, setOptions] = React.useState({
    label: placeholder,
    selectedLabel: undefined,
    value: null,
    isOpened: false,
  })
  const selectRef = React.useRef(null)

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOptions({ ...options, isOpened: false })
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [options, selectRef])

  React.useEffect(() => {
    if (value && data.length && (!options.value || options.value !== value)) {
      const selectedOption = data.find((option) => option.value === value)

      if (selectedOption) {
        setOptions({
          label: selectedOption.label,
          selectedLabel: selectedOption.selectedLabel,
          value,
          isOpened: false,
        })
      }
    }
  }, [value, data])

  return (
    <Box ref={selectRef} sx={{ position: "relative", ...sx }}>
      <Flex
        onClick={() => {
          setOptions({ ...options, isOpened: !options.isOpened })
        }}
        sx={{
          position: "relative",
          height: 14,
          alignItems: "center",
          border: "1px solid",
          borderColor: hasError ? "red" : "grayscale.300",
          cursor: "pointer",
          paddingInline: 4,
        }}
      >
        <Paragraph
          sx={{
            color: options.label === placeholder ? "grayscale.600" : "primary",
            fontSize: "sm",
            transition: "margin-block-start .2s",
            marginBlockStart:
              hasFloatingLabel && options.label !== placeholder && 3,
          }}
        >
          {options.selectedLabel || options.label}
        </Paragraph>
        {hasFloatingLabel && (
          <Paragraph
            sx={{
              fontSize: "sm",
              color: hasError ? "red" : "grayscale.600",
              opacity: options.label === placeholder ? 0 : 1,
              visibility: options.label === placeholder ? "hidden" : "visible",
              position: "absolute",
              top: "50%",
              left: 4,
              pointerEvents: "none",
              transform:
                options.label === placeholder
                  ? "translateY(-50%)"
                  : "scale(.72) translateY(-26px)",
              transformOrigin: "left top",
              transition: "transform .2s, opacity .2s, visibility .2s",
            }}
          >
            {placeholder}
          </Paragraph>
        )}
        <Icon
          name="chevron-down"
          size={5}
          sx={{
            color: "grayscale.600",
            marginInlineStart: "auto",
          }}
        />
      </Flex>
      <Box
        as="ul"
        sx={{
          maxHeight: 75,
          overflowY: "scroll",
          color: "grayscale.600",
          fontSize: "xs",
          width: "100%",
          display: options.isOpened ? "block" : "none",
          position: hasFloatingDropdown ? "absolute" : "initial",
          top: hasFloatingDropdown && 14,
          left: hasFloatingDropdown && 0,
          opacity: options.isOpened ? 1 : 0,
          visibility: options.isOpened ? "visible" : "hidden",
          backgroundColor: "grayscale.white",
          border: "1px solid",
          borderColor: "grayscale.100",
          zIndex: "selectDropdown",
        }}
      >
        {data.map((option, index) => (
          <Box
            onClick={() => {
              setOptions({
                label: option.label,
                selectedLabel: option.selectedLabel,
                value: option.value,
                isOpened: false,
              })
              onValueChange && onValueChange(option.value)
            }}
            sx={{
              cursor: "pointer",
              color: "primary",
              backgroundColor:
                options.value === option.value && "grayscale.100",
              paddingInline: 4,
              transition: "color .2s, background-color .2s",
              ":hover": {
                color: "primary",
                backgroundColor: "grayscale.100",
              },
            }}
          >
            <Box
              sx={{
                borderBlockEnd: index + 1 !== data.length && "1px solid",
                borderBlockEndColor: "grayscale.100",
                paddingBlock: 3,
                marginBlockEnd: "-1px",
              }}
            >
              <Box
                sx={{
                  display: [null, "flex"],
                  justifyContent: "space-between",
                }}
              >
                <Text sx={{ display: ["block", "inline"] }}>
                  {option.label}
                </Text>
                <Text>{option.subLabel && option.subLabel}</Text>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {errorMessage && (
        <Paragraph sx={{ color: "red", fontSize: "sm", marginBlockStart: 1 }}>
          {errorMessage}
        </Paragraph>
      )}
      <SelectBox {...selectProps}>
        {data.map((option) => (
          <option
            selected={options.value === option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </SelectBox>
    </Box>
  )
}
