import * as React from "react"
import { Box, BoxProps, Input, InputProps, Label, Text } from "theme-ui"

export const RadioInput: React.FC<InputProps> = ({ sx, ...inputProps }) => {
  return (
    <Input
      type="radio"
      sx={{
        position: "absolute",
        width: "1px",
        height: "1px",
        border: 0,
        overflow: "hidden",
        whiteSpace: "nowrap",
        clip: "rect(0px, 0px, 0px, 0px)",
        padding: 0,
        margin: "-1px",
        ...sx,
      }}
      {...inputProps}
    />
  )
}

export interface RadioIndicatorProps extends BoxProps {
  isChecked?: boolean
  size?: "sm" | "md"
}

export const RadioIndicator: React.FC<RadioIndicatorProps> = ({
  isChecked,
  size = "md",
  sx,
  ...boxProps
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: size === "md" ? 4 : 3,
        height: size === "md" ? 4 : 3,
        color: isChecked && "grayscale.white",
        border: "1px solid",
        borderColor: "grayscale.700",
        borderRadius: "100%",
        backgroundColor: isChecked && "primary",
        transition: "background-color .2s, border-color .2s",
        ...sx,
      }}
      {...boxProps}
    />
  )
}

export interface RadioProps extends InputProps {
  label?: string
  isChecked?: boolean
  orientation?: "start" | "end"
}

export const Radio: React.FC<RadioProps> = ({
  label,
  isChecked,
  orientation = "start",
  sx,
  children,
  ...inputProps
}) => {
  return (
    <Box sx={{ display: "inline-block", ...sx }}>
      <Label sx={{ display: "flex", fontSize: "sm", cursor: "pointer" }}>
        <RadioInput checked={isChecked} {...inputProps} />
        <RadioIndicator
          isChecked={isChecked}
          sx={{
            order: label && orientation === "start" ? 0 : 1,
            marginBlockStart: label && "1px",
            marginInlineStart: label && orientation === "end" && 3,
            marginInlineEnd: label && orientation === "start" && 3,
          }}
        />
        {label && <Text sx={{ flex: 1 }}>{label}</Text>}
      </Label>
    </Box>
  )
}
