// External packages
import * as React from "react"
import { navigate } from "gatsby-link"
import { Box, Flex, Paragraph, Text, Image } from "theme-ui"
import { z } from "zod"
import { CountryCode, parsePhoneNumber } from "libphonenumber-js"
import { StorePostCartsCartReq } from "@medusajs/medusa"
import AdyenCheckout from "@adyen/adyen-web"
import type { CoreOptions } from "@adyen/adyen-web/dist/types/core/types"
import type { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types"
import qs from "query-string"
import * as Accordion from "@radix-ui/react-accordion"
import { useSpring, animated } from "@react-spring/web"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Services
import {
  trackCheckoutStepViewed,
  trackCheckoutStepCompleted,
} from "../../../services/analytics"

// Hooks
import {
  useAdyenAdditionalDetails,
  useAdyenMakePayment,
} from "../../../hooks/adyen/adyen"
import { useZendesk } from "../../../hooks/v2/useZendesk"

// Utilities
import { formatMoneyAmount } from "../../../utils/prices"
import { hasCartGiftcard } from "../utils/cart/hasCartGiftcard"

// Components
import Form from "../form/Form"
import CheckboxField from "../form/CheckboxField"
import { Link } from "../Link"
import Details, { countriesCodeSelectData } from "./Details"
import Shipping from "./Shipping"
import { CheckoutStepAction } from "./Checkout"
import { Button } from "../Button"
import { RadioIndicator } from "../ui/Radio"

// Assets
import "../../../assets/css/adyen.css"
import Mastercard from "../../../assets/pay-icons/mastercard.svg"
import Amex from "../../../assets/pay-icons/amex.svg"
import Mobilepay from "../../../assets/pay-icons/mobilepay.svg"
import Klarna from "../../../assets/pay-icons/klarna.svg"
import VISA from "../../../assets/pay-icons/visa.svg"
import Paypal from "../../../assets/pay-icons/paypal.svg"
import Giropay from "../../../assets/pay-icons/giropay.svg"
import Ideal from "../../../assets/pay-icons/ideal.svg"
import Applepay from "../../../assets/pay-icons/applepay.svg"
import Googlepay from "../../../assets/pay-icons/googlepay.svg"
import Unionpay from "../../../assets/pay-icons/unionpay.svg"
import Kakaopay from "../../../assets/pay-icons/kakaopay.svg"
import JCB from "../../../assets/pay-icons/jcb.svg"
import Bancontact from "../../../assets/pay-icons/bancontact.svg"
import Vipps from "../../../assets/pay-icons/vipps.svg"
import Twint from "../../../assets/pay-icons/twint.svg"
import CarteBancaire from "../../../assets/pay-icons/cartebancaire.svg"
import Swish from "../../../assets/pay-icons/swish.svg"
import { PayPalElementProps } from "@adyen/adyen-web/dist/types/components/PayPal/types"
import { useUpdatePaymentSession } from "medusa-react"

const ADYEN_CLIENT_KEY =
  process.env.GATSBY_ADYEN_CLIENT_KEY || "test_DR4K4WGBFNBDPIFLTPRE4PWIQUHEKQE2"
const ADYEN_ENVIRONMENT = process.env.GATSBY_ADYEN_ENVIRONMENT || "test"

const reviewSchema = z
  .object({
    "payment-method": z.string(),
    "billing-address": z.boolean(),
    "billing-address-first-name": z.string().optional(),
    "billing-address-last-name": z.string().optional(),
    "billing-address-1": z.string().optional(),
    "billing-address-2": z.string().optional(),
    "billing-address-postal-code": z.string().optional(),
    "billing-address-city": z.string().optional(),
    "billing-address-country-code": z.string().min(1).optional(),
    "billing-address-phone": z.string().optional(),
    "billing-address-country": z.string().optional(),
  })
  .refine(
    // TODO: Get validation working
    (data) => {
      if (!data["billing-address"]) {
        return (
          data["billing-address-first-name"] &&
          data["billing-address-last-name"] &&
          data["billing-address-1"] &&
          data["billing-address-postal-code"] &&
          data["billing-address-city"] &&
          data["billing-address-country-code"] &&
          data["billing-address-phone"] &&
          data["billing-address-country"]
        )
      }

      return true
    },
    {
      message: "Please fill out the billing address fields",
    }
  )

export const ReviewAdyen: React.FC<CheckoutStepAction> = ({
  previousStep,
  isSwap,
}) => {
  const store = useStore()
  const updateSession = useUpdatePaymentSession(store.cart?.id)

  let allowedPaymentMethods: PaymentMethodsResponse | undefined =
    store.cart?.payment_session?.data

  if (
    allowedPaymentMethods?.paymentMethods?.length &&
    store.cart?.context?.split_order
  ) {
    allowedPaymentMethods.paymentMethods =
      allowedPaymentMethods.paymentMethods.filter((pm) => pm.type === "scheme")
  }

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [reviewFieldsets, setReviewFieldsets] = React.useState({
    details: {
      isEditable:
        !store?.cart?.shipping_address.address_1 ||
        !store?.cart?.shipping_address.city ||
        !store?.cart?.shipping_address.postal_code,
    },
    shipping: {
      isEditable: !store.cart?.shipping_methods || isSwap,
    },
  })
  const isOnlyGiftCardShippingMethods = store?.cart?.shipping_methods?.every(
    (sm) =>
      sm.shipping_option.name === "Printed Copy" ||
      sm.shipping_option.name === "Digital Copy"
  )
  const adyenMakePaymentMutation = useAdyenMakePayment({
    cartId: store.cart?.id,
  })
  const adyenAdditionalDetailsMutation = useAdyenAdditionalDetails({
    cartId: store.cart?.id,
  })
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()
  const [options, setOptions] = React.useState({
    googlepay: false,
    applepay: false,
    accordionValue: "",
  })

  const cardsRef = React.useRef()
  const googlePayRef = React.useRef()
  const applePayRef = React.useRef()
  const klarnaRef = React.useRef()
  const klarnaPayNow = React.useRef()
  const klarnaOverTime = React.useRef()
  const mobilePayRef = React.useRef()
  const idealRef = React.useRef()
  const paypalRef = React.useRef()
  const kakaoPayRef = React.useRef()
  const vippsRef = React.useRef()
  const twintRef = React.useRef()
  const bancontactRef = React.useRef()
  const swishRef = React.useRef()

  React.useEffect(() => {
    const fn = async () => {
      const configuration: CoreOptions = {
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        analytics: {
          enabled: true,
        },
        countryCode: store.cart.shipping_address.country_code.toUpperCase(),
        amount: {
          value: store.cart.total,
          currency: store.cart.region.currency_code.toUpperCase(),
        },
        paymentMethodsResponse: allowedPaymentMethods,
        onSubmit: async (state, dropin) => {
          if (adyenMakePaymentMutation.isLoading) {
            return
          }

          adyenMakePaymentMutation.mutate(
            {
              paymentMethodData: state?.data?.paymentMethod,
              browserInfo: state?.data?.browserInfo,
              isArchive: true,
            },
            {
              onSuccess: (res) => {
                if (res?.resultCode === "Refused") {
                  setErrorMessage(res?.refusalReasonCode)
                  dropin
                    .setStatus("error", {
                      message: res?.refusalReasonCode,
                    })
                    .remount()

                  return
                }

                updateSession.mutate(
                  {
                    provider_id: "adyen",
                    data: store.cart?.context?.split_order
                      ? store.cart?.payment_session?.data
                      : res,
                  },
                  {
                    onSuccess: ({ cart }) => {
                      if (!res?.action?.type) {
                        navigate(`/checkout/payment?mpr=${cart.id}`)
                      } else {
                        dropin.handleAction(res.action)
                      }
                    },
                    onError: () => {
                      setErrorMessage("10")
                      dropin
                        .setStatus("error", {
                          message: "10",
                        })
                        .remount()
                    },
                  }
                )
              },
            }
          )
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component)
        },
        showPayButton: true,
        // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
        // For example, this is 3D Secure configuration for cards:
        paymentMethodsConfiguration: {
          card: {
            showBrandsUnderCardNumber: false,
            hasHolderName: false,
            holderNameRequired: false,
            billingAddressRequired: false,
            styles: {
              base: {
                color: "#181818",
                fontSize: "14px",
                padding: "0 0 2px 0",
              },
              placeholder: {
                color: "#C5C5C5",
              },
            },
          },
          maestro: {
            icon: Mastercard,
          },
          visa: {
            icon: VISA,
          },
          amex: {
            icon: Amex,
          },
          giropay: {
            icon: Giropay,
          },
        },
      }

      const checkout = await AdyenCheckout(configuration)

      const mappedMethods = allowedPaymentMethods?.paymentMethods?.map(
        (pm) => pm.type
      )

      if (mappedMethods.includes("scheme")) {
        checkout.create("card").mount(cardsRef.current)
      }

      let googlePayAvailable = false
      let applePayAvailable = false

      if (mappedMethods.includes("googlepay") && googlePayRef.current) {
        try {
          const googlePay = checkout.create("googlepay", {
            amount: {
              value: store.cart.total,
              currency: store.cart.region.currency_code.toUpperCase(),
            },
          })

          const available = await googlePay.isAvailable()

          if (available) {
            googlePay.mount(googlePayRef.current)
            googlePayAvailable = true
          }
        } catch (error) {
          console.error(error)
        }
      }

      if (mappedMethods.includes("applepay") && applePayRef.current) {
        try {
          const applePay = checkout.create("applepay", {
            countryCode: store.cart.shipping_address.country_code.toUpperCase(),
            amount: {
              value: store.cart.total,
              currency: store.cart.region.currency_code.toUpperCase(),
            },
          })

          const available = await applePay.isAvailable()

          if (available) {
            applePay.mount(applePayRef.current)
            applePayAvailable = true
          }
        } catch (error) {
          console.error(error)
        }
      }

      if (mappedMethods.includes("klarna") && klarnaRef.current) {
        checkout.create("klarna").mount(klarnaRef.current)
      }

      if (mappedMethods.includes("klarna_paynow") && klarnaPayNow.current) {
        checkout.create("klarna_paynow").mount(klarnaPayNow.current)
      }

      if (mappedMethods.includes("klarna_account") && klarnaOverTime.current) {
        checkout.create("klarna_account").mount(klarnaOverTime.current)
      }

      if (mappedMethods.includes("mobilepay") && mobilePayRef.current) {
        checkout.create("mobilepay").mount(mobilePayRef.current)
      }

      if (mappedMethods.includes("ideal") && idealRef.current) {
        checkout.create("ideal").mount(idealRef.current)
      }

      if (mappedMethods.includes("vipps") && vippsRef.current) {
        checkout.create("vipps").mount(vippsRef.current)
      }

      if (mappedMethods.includes("twint") && twintRef.current) {
        checkout.create("twint").mount(twintRef.current)
      }

      if (mappedMethods.includes("bcmc") && bancontactRef.current) {
        checkout.create("bcmc").mount(bancontactRef.current)
      }

      if (mappedMethods.includes("kakaopay") && kakaoPayRef.current) {
        checkout.create("kakaopay").mount(kakaoPayRef.current)
      }

      if (mappedMethods.includes("swish") && swishRef.current) {
        checkout.create("swish").mount(swishRef.current)
      }

      if (mappedMethods.includes("paypal") && paypalRef.current) {
        const paypalConfig: PayPalElementProps = {
          blockPayPalCreditButton: true,
          blockPayPalPayLaterButton: true,
          blockPayPalVenmoButton: true,
          onAdditionalDetails(state, dropin) {
            adyenAdditionalDetailsMutation.mutate(
              {
                paymentMethodData: state?.data,
              },
              {
                onSuccess: (res) => {
                  if (res?.resultCode === "Refused") {
                    setErrorMessage(res?.refusalReasonCode)
                    dropin
                      .setStatus("error", {
                        message: res?.refusalReasonCode,
                      })
                      .remount()

                    return
                  }

                  updateSession.mutate(
                    {
                      provider_id: "adyen",
                      data: res,
                    },
                    {
                      onSuccess: ({ cart }) => {
                        navigate(`/checkout/payment?mpr=${cart.id}`)
                      },
                      onError: () => {
                        setErrorMessage("10")
                        dropin
                          .setStatus("error", {
                            message: "10",
                          })
                          .remount()
                      },
                    }
                  )
                },
              }
            )
          },
        }
        checkout.create("paypal", paypalConfig).mount(paypalRef.current)
      }

      setTimeout(() => {
        setOptions({
          googlepay: googlePayAvailable,
          applepay: applePayAvailable,
          accordionValue: mappedMethods?.filter((pm) =>
            pm === "googlepay"
              ? googlePayAvailable
              : pm === "applepay"
              ? applePayAvailable
              : true
          )?.[0],
        })
      }, 700)
    }

    if (
      cardsRef.current &&
      store?.cart?.payment_session?.data &&
      store.cart?.payment_session?.provider_id === "adyen" &&
      allowedPaymentMethods?.paymentMethods?.length
    ) {
      fn()
    }
  }, [
    store.cart?.payment_session?.provider_id ||
      allowedPaymentMethods ||
      cardsRef,
  ])

  React.useEffect(() => {
    if (store?.cart?.id) {
      if (!isSwap) {
        trackCheckoutStepViewed(store?.cart, 3, "payment")
      }
    }
  }, [])

  React.useEffect(() => {
    let { paymentRefused } = qs.parse(window.location.search)

    if (paymentRefused) {
      if (Array.isArray(paymentRefused)) {
        paymentRefused = paymentRefused[0]
      }

      setErrorMessage(paymentRefused)
    }
  }, [store.cart])

  let combinedAddress = store?.cart?.shipping_address?.address_2
    ? `${store?.cart?.shipping_address?.address_1}, ${store?.cart?.shipping_address?.address_2}`
    : store?.cart?.shipping_address?.address_1

  return (
    <>
      <Form
        schema={reviewSchema}
        onSubmit={async (values) => {
          const callingCodeCountry = countriesCodeSelectData.find(
            (c) =>
              c.value === values["billing-address-country-code"]?.toUpperCase()
          )?.value

          if (!values["billing-address"]) {
            const update: StorePostCartsCartReq = {
              billing_address: {
                first_name: values["billing-address-first-name"],
                last_name: values["billing-address-last-name"],
                address_1: values["billing-address-1"],
                address_2: values["billing-address-2"],
                postal_code: values["billing-address-postal-code"],
                city: values["billing-address-city"],
                phone: parsePhoneNumber(
                  values["billing-address-phone"],
                  callingCodeCountry ??
                    (values[
                      "billing-address-country"
                    ].toUpperCase() as CountryCode)
                ).number,
                country_code: values["billing-address-country"],
              },
            }

            await store?.updateCart?.mutateAsync(update)
          }
        }}
        defaultValues={{
          "billing-address": true,
          "billing-address-country-code":
            store?.cart?.shipping_address?.country_code?.toUpperCase() ??
            undefined,
        }}
      >
        <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
          Details
        </Paragraph>
        <Box
          sx={{
            color: "grayscale.600",
            position: "relative",
            fontSize: "sm",
            border: "1px solid",
            borderColor: "grayscale.300",
            paddingBlock: 5,
            paddingInlineStart: 5,
            paddingInlineEnd: reviewFieldsets.details.isEditable ? 5 : 7,
            marginBlockEnd: 9,
          }}
        >
          {reviewFieldsets.details.isEditable ? (
            <Details
              isSwap={isSwap}
              previousStep={() => {
                setReviewFieldsets({
                  ...reviewFieldsets,
                  details: {
                    isEditable: false,
                  },
                })
              }}
              previousStepLabel="Cancel"
              nextStep={() =>
                setReviewFieldsets({
                  ...reviewFieldsets,
                  details: {
                    isEditable: false,
                  },
                })
              }
              nextStepLabel="Confirm"
              isInEditable
            />
          ) : (
            <>
              <Paragraph sx={{ marginBlockEnd: 2 }}>
                {store?.cart?.email}
              </Paragraph>
              <Paragraph
                sx={{ marginBlockEnd: 2 }}
              >{`${store?.cart?.shipping_address?.first_name} ${store?.cart?.shipping_address?.last_name}`}</Paragraph>
              <Paragraph sx={{ marginBlockEnd: 2 }}>
                {`${combinedAddress}, ${
                  store?.cart?.shipping_address?.postal_code
                } ${store?.cart?.shipping_address?.city}, ${
                  store?.cart?.region?.countries?.find(
                    (country) =>
                      country.iso_2 ===
                      store?.cart?.shipping_address?.country_code
                  )?.display_name
                }`}
              </Paragraph>
              <Paragraph>{store?.cart?.shipping_address?.phone}</Paragraph>
              <Link
                onClick={() => {
                  reviewFieldsets.shipping.isEditable === false
                  setReviewFieldsets({
                    ...reviewFieldsets,
                    details: {
                      isEditable: true,
                    },
                  })
                }}
                sx={{
                  color: "grayscale.600",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: "xs",
                  backgroundColor: "grayscale.white",
                  transform: "translateY(-50%)",
                  paddingInlineEnd: 2,
                  paddingInlineStart: 2,
                }}
              >
                Edit
              </Link>
            </>
          )}
        </Box>
        <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
          Shipping method
        </Paragraph>
        <Box
          sx={{
            color: "grayscale.600",
            position: "relative",
            fontSize: "sm",
            border: "1px solid",
            borderColor: "grayscale.300",
            paddingBlock: 5,
            paddingInlineStart: 5,
            paddingInlineEnd: reviewFieldsets.shipping.isEditable ? 5 : 7,
            marginBlockEnd: 9,
          }}
        >
          {reviewFieldsets.shipping.isEditable ||
          (!store?.cart?.shipping_methods?.length &&
            !isOnlyGiftCardShippingMethods) ? (
            <Shipping
              previousStep={() => {
                setReviewFieldsets({
                  ...reviewFieldsets,
                  shipping: {
                    isEditable: false,
                  },
                })
              }}
              previousStepLabel="Cancel"
              nextStep={() =>
                setReviewFieldsets({
                  ...reviewFieldsets,
                  shipping: {
                    isEditable: false,
                  },
                })
              }
              nextStepLabel="Confirm"
              isInEditable
            />
          ) : (
            <>
              <Flex sx={{ flexDirection: "column", gap: 2 }}>
                {hasCartGiftcard(store?.cart) && (
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <Box>
                      <Paragraph>
                        Send gift card to {store?.cart?.email}
                      </Paragraph>
                    </Box>
                    <Paragraph sx={{ whiteSpace: "nowrap" }}>Free</Paragraph>
                  </Flex>
                )}
                {store?.cart?.shipping_methods
                  ?.filter(
                    (i) =>
                      i.shipping_option.name !== "Printed Copy" &&
                      i.shipping_option.name !== "Digital Copy"
                  )
                  .map((item) => (
                    <Flex
                      sx={{ justifyContent: "space-between" }}
                      key={item.id}
                    >
                      <Box>
                        <Paragraph>{item?.shipping_option?.name}</Paragraph>
                      </Box>
                      <Paragraph sx={{ whiteSpace: "nowrap" }}>
                        {store?.cart?.shipping_methods?.[0]?.total === 0
                          ? "Free"
                          : formatMoneyAmount({
                              amount:
                                store?.cart?.region?.name === "United States"
                                  ? store?.cart?.shipping_methods?.[0]?.subtotal
                                  : store?.cart?.shipping_methods?.[0]?.total,
                              currencyCode: store?.cart?.region?.currency_code,
                            })}
                      </Paragraph>
                    </Flex>
                  ))}
              </Flex>
              <Link
                onClick={() => {
                  reviewFieldsets.details.isEditable === false &&
                    setReviewFieldsets({
                      ...reviewFieldsets,
                      shipping: {
                        isEditable: true,
                      },
                    })
                }}
                sx={{
                  color: "grayscale.600",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: "xs",
                  backgroundColor: "grayscale.white",
                  transform: "translateY(-50%)",
                  paddingInlineStart: 2,
                  paddingInlineEnd: 2,
                }}
              >
                Edit
              </Link>
            </>
          )}
        </Box>
        <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
          Select payment method
        </Paragraph>
        <Accordion.Root
          type="single"
          collapsible
          value={options.accordionValue}
          asChild
        >
          <Flex sx={{ flexDirection: "column", gap: 5, fontSize: "sm" }}>
            {allowedPaymentMethods?.paymentMethods?.map((pm) => {
              return (
                <Accordion.Item asChild value={pm.type} key={pm.type}>
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "grayscale.300",
                      display:
                        pm.type === "googlepay" || pm.type === "applepay"
                          ? options[pm.type]
                            ? "block"
                            : "none"
                          : "block",
                    }}
                  >
                    <Accordion.Trigger
                      asChild
                      onClick={() =>
                        setOptions({ ...options, accordionValue: pm.type })
                      }
                    >
                      <Flex
                        as="button"
                        sx={{
                          all: "unset",
                          boxSizing: "border-box",
                          alignItems: "center",
                          width: "100%",
                          gap: 2,
                          cursor: "pointer",
                          paddingInline: 4,
                          paddingBlock: 3,
                        }}
                      >
                        <RadioIndicator
                          isChecked={options.accordionValue === pm.type}
                          size="sm"
                          sx={{ marginBlockStart: "-1px" }}
                        />
                        {Boolean(pm.name) && <Text>{pm.name}</Text>}
                        <Box sx={{ marginInlineStart: "auto" }}>
                          {pm.type === "scheme" ? (
                            <Flex sx={{ gap: 1 }}>
                              {pm.brands?.map((brand) => {
                                if (brand === "mc") {
                                  return (
                                    <Box key={brand}>
                                      <Image
                                        src={Mastercard}
                                        sx={{
                                          display: "block",
                                          objectFit: "contain",
                                          width: 7,
                                          height: 7,
                                        }}
                                      />
                                    </Box>
                                  )
                                }

                                if (brand === "amex") {
                                  return (
                                    <Box key={brand}>
                                      <Image
                                        src={Amex}
                                        sx={{
                                          display: "block",
                                          objectFit: "contain",
                                          width: 7,
                                          height: 7,
                                        }}
                                      />
                                    </Box>
                                  )
                                }

                                if (brand === "visa") {
                                  return (
                                    <Box key={brand}>
                                      <Image
                                        src={VISA}
                                        sx={{
                                          display: "block",
                                          objectFit: "contain",
                                          width: 7,
                                          height: 7,
                                        }}
                                      />
                                    </Box>
                                  )
                                }

                                if (brand === "cup") {
                                  return (
                                    <Box key={brand}>
                                      <Image
                                        src={Unionpay}
                                        sx={{
                                          display: "block",
                                          objectFit: "contain",
                                          width: 7,
                                          height: 7,
                                        }}
                                      />
                                    </Box>
                                  )
                                }

                                if (brand === "jcb") {
                                  return (
                                    <Box key={brand}>
                                      <Image
                                        src={JCB}
                                        sx={{
                                          display: "block",
                                          objectFit: "contain",
                                          width: 7,
                                          height: 7,
                                        }}
                                      />
                                    </Box>
                                  )
                                }

                                if (brand === "cartebancaire") {
                                  return (
                                    <Box key={brand}>
                                      <Image
                                        src={CarteBancaire}
                                        sx={{
                                          display: "block",
                                          objectFit: "contain",
                                          width: 7,
                                          height: 7,
                                        }}
                                      />
                                    </Box>
                                  )
                                }
                              })}
                            </Flex>
                          ) : pm.type === "googlepay" ? (
                            <Box>
                              <Image
                                src={Googlepay}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "applepay" ? (
                            <Box>
                              <Image
                                src={Applepay}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 10,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "klarna" ? (
                            <Box>
                              <Image
                                src={Klarna}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "klarna_account" ? (
                            <Box>
                              <Image
                                src={Klarna}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "klarna_paynow" ? (
                            <Box>
                              <Image
                                src={Klarna}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "mobilepay" ? (
                            <Box>
                              <Image
                                src={Mobilepay}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "ideal" ? (
                            <Box>
                              <Image
                                src={Ideal}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "paypal" ? (
                            <Box>
                              <Image
                                src={Paypal}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "kakaopay" ? (
                            <Box>
                              <Image
                                src={Kakaopay}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "bcmc" ? (
                            <Box>
                              <Image
                                src={Bancontact}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "vipps" ? (
                            <Box>
                              <Image
                                src={Vipps}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "twint" ? (
                            <Box>
                              <Image
                                src={Twint}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : pm.type === "swish" ? (
                            <Box>
                              <Image
                                src={Swish}
                                sx={{
                                  display: "block",
                                  objectFit: "contain",
                                  width: 12,
                                  height: 7,
                                }}
                              />
                            </Box>
                          ) : null}
                        </Box>
                      </Flex>
                    </Accordion.Trigger>
                    <Accordion.Content forceMount>
                      <AnimatedAccordionContent
                        isOpen={options.accordionValue === pm.type}
                        isSwish={pm.type === "swish"}
                      >
                        <Box sx={{ padding: 4, paddingBlockStart: 0 }}>
                          {pm.type === "scheme" ? (
                            <div ref={cardsRef} />
                          ) : pm.type === "googlepay" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={googlePayRef} />
                            </>
                          ) : pm.type === "applepay" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={applePayRef} />
                            </>
                          ) : pm.type === "klarna" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={klarnaRef} />
                            </>
                          ) : pm.type === "klarna_account" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={klarnaOverTime} />
                            </>
                          ) : pm.type === "klarna_paynow" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={klarnaPayNow} />
                            </>
                          ) : pm.type === "mobilepay" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={mobilePayRef} />
                            </>
                          ) : pm.type === "ideal" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={idealRef} />
                            </>
                          ) : pm.type === "paypal" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={paypalRef} />
                            </>
                          ) : pm.type === "kakaopay" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={kakaoPayRef} />
                            </>
                          ) : pm.type === "vipps" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={vippsRef} />
                            </>
                          ) : pm.type === "twint" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={twintRef} />
                            </>
                          ) : pm.type === "bcmc" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={bancontactRef} />
                            </>
                          ) : pm.type === "swish" ? (
                            <>
                              <Paragraph
                                sx={{
                                  color: "grayscale.600",
                                  marginBlockEnd: 6,
                                }}
                              >
                                Another step will appear to securely complete
                                your transaction.
                              </Paragraph>
                              <div ref={swishRef} />
                            </>
                          ) : null}
                        </Box>
                      </AnimatedAccordionContent>
                    </Accordion.Content>
                  </Box>
                </Accordion.Item>
              )
            })}
          </Flex>
        </Accordion.Root>
        {errorMessage && (
          <Paragraph
            sx={{
              fontSize: "xs",
              color: "red",
              marginBlockStart: 9,
              marginBlockEnd: -5,
            }}
          >
            {errorMessage === "12" ? (
              <>
                We failed to process your payment due to insufficient funds.
                Please try again with a different payment method or contact{" "}
                <Link
                  onClick={() => setIsActivated(true)}
                  sx={{ textDecoration: "underline" }}
                >
                  customer service
                </Link>{" "}
                for help
              </>
            ) : (
              <>
                We failed to process your payment. Please try again. If the
                issue continues, contact{" "}
                <Link
                  onClick={() => setIsActivated(true)}
                  sx={{ textDecoration: "underline" }}
                >
                  customer service
                </Link>{" "}
                for help
              </>
            )}
          </Paragraph>
        )}
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            gap: [4, 6],
            marginBlockStart: 9,
          }}
        >
          <Button
            variant="secondary"
            onClick={() => {
              if (store?.pay?.isLoading || store?.updateCart?.isLoading) {
                return
              }

              previousStep()
            }}
            sx={{ flex: [null, 1] }}
          >
            Back
          </Button>
        </Flex>
      </Form>
      {isActivated && <ZendeskWidget />}
    </>
  )
}

const AnimatedAccordionContent: React.FC<
  Accordion.AccordionContentProps & { isOpen: boolean; isSwish: boolean }
> = ({ isOpen, isSwish, children, ...rest }) => {
  const ref = React.useRef(null)
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight)
    }
  }, [isOpen, ref.current])

  return (
    <animated.div
      style={useSpring({
        height: isOpen ? (isSwish ? "auto" : height) : 0,
        overflow: "hidden",
      })}
    >
      <div ref={ref} {...rest}>
        {children}
      </div>
    </animated.div>
  )
}
