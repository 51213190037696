import * as React from "react"
import { Box, Flex, FlexProps, Paragraph } from "theme-ui"
import { Collapse } from "react-collapse"

import { Cart } from "@medusajs/medusa"
import { RadioIndicator, RadioInput } from "./Radio"

export interface RadioCardGroupProps extends FlexProps {
  data: {
    label: string
    subLabel?: string
    sideContent?: React.ReactNode
    hideSideContentOnOpen?: boolean
    collapseContent?: React.ReactNode
    value: string
    hide?: boolean
    canMakePayment?: boolean
    setCanMakePayment?: React.Dispatch<React.SetStateAction<boolean>>
    paymentRequest?: any
    setPaymentRequest?: React.Dispatch<React.SetStateAction<any>>
    onPaymentCompleted?: (
      updatedCart: Omit<Cart, "refundable_amount" | "refunded_total">
    ) => void
  }[]
  defaultValue?: null | string
  flexDirection?: "column" | "row"
  hasError?: boolean
  errorMessage?: string
  onValueChange?: (value: string) => void
}

export const RadioCardGroup: React.FC<RadioCardGroupProps> = ({
  data,
  defaultValue = null,
  flexDirection = "column",
  hasError,
  errorMessage,
  onValueChange,
  sx,
  ...flexProps
}) => {
  const [value, setValue] = React.useState(defaultValue)

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <>
      <Box sx={{ ...sx }}>
        <Flex
          sx={{
            flexDirection:
              flexDirection === "column" ? "column" : ["column", "row"],
            gap: 5,
          }}
          {...flexProps}
        >
          {data.map((item) => (
            <Box
              onClick={() => {
                setValue(item.value)
                onValueChange && onValueChange(item.value)
              }}
              sx={{
                flex: flexDirection === "row" && 1,
                color: item.value === value ? "primary" : "grayscale.600",
                border: "1px solid",
                borderColor: hasError
                  ? "red"
                  : item.value === value
                  ? "grayscale.600"
                  : "grayscale.300",
                cursor: "pointer",
                transition: "color .2s, border-color .2s",
                paddingBlock: 3,
                paddingInline: 4,
              }}
            >
              <Flex>
                <RadioInput value={item.value} checked={item.value === value} />
                <RadioIndicator
                  isChecked={item.value === value}
                  size="sm"
                  sx={{
                    borderColor: hasError
                      ? "red"
                      : item.value === value
                      ? "primary"
                      : "grayscale.600",
                    marginBlockStart: 1,
                    marginInlineEnd: 2,
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <Paragraph
                      sx={{
                        fontSize: "sm",
                        color: hasError && "red",
                        transition: "color .2s",
                      }}
                    >
                      {item.label}
                    </Paragraph>
                    <Box
                      sx={{
                        display: [null, "none"],
                        whiteSpace: "nowrap",
                        opacity:
                          (item.hideSideContentOnOpen &&
                            item.value !== value) ||
                          !item.hideSideContentOnOpen
                            ? 1
                            : 0,
                        marginInlineStart: 2,
                        transition: "opacity .2s",
                      }}
                    >
                      {item.sideContent}
                    </Box>
                  </Flex>
                  {item.subLabel && (
                    <Paragraph
                      sx={{ color: hasError && "red", fontSize: "xs" }}
                    >
                      {item.subLabel}
                    </Paragraph>
                  )}
                </Box>
                <Box
                  sx={{
                    color: hasError && "red",
                    display: ["none", "block"],
                    opacity:
                      (item.hideSideContentOnOpen && item.value !== value) ||
                      !item.hideSideContentOnOpen
                        ? 1
                        : 0,
                    transition: "opacity .2s",
                    marginInlineStart: 2,
                  }}
                >
                  {item.sideContent}
                </Box>
              </Flex>
              {item.collapseContent && (
                <Collapse isOpened={item.value === value}>
                  {item.collapseContent}
                </Collapse>
              )}
            </Box>
          ))}
        </Flex>
        {errorMessage && hasError && (
          <Paragraph sx={{ color: "red", fontSize: "sm", marginBlockStart: 4 }}>
            {errorMessage}
          </Paragraph>
        )}
      </Box>
    </>
  )
}
