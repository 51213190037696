import * as React from "react"
import { Box, Flex, Text } from "theme-ui"

import { Select } from "../ui/Select"
import { Button } from "../Button"
import { Input } from "../ui/Input"
import { Collapse } from "../ui/Collapse"

const ParcelWidget = ({
  loading,
  options,
  hasError,
  onSearch,
  onParcelShopSelected,
  value,
}) => {
  const [q, setQ] = React.useState("")

  return options?.length && !loading ? (
    <>
      <Box
        sx={{
          paddingInlineStart: [null, 4],
          paddingBlockStart: 5,
          paddingBlockEnd: 2,
        }}
      >
        <Select
          placeholder="Select parcel shop"
          hasFloatingLabel
          onValueChange={(value) => onParcelShopSelected(value)}
          data={
            options.map((o) => {
              return {
                value: o.id,
                label: o.name,
                subLabel: `${o.address_1}, ${o.zip} ${o.city}`,
              }
            }) || []
          }
          sx={{ marginBlockEnd: 3 }}
          hasError={hasError}
          value={value}
        />
        <Collapse
          labelWhenOpened="Show less"
          labelWhenClosed="Shipping to a different postal code"
          labelStyle={{ marginInlineEnd: "auto" }}
          labelPosition="end"
          isFullWidth={true}
        >
          <Flex
            sx={{
              paddingBlockStart: 2,
              paddingBlockEnd: 3,
            }}
          >
            <Input
              placeholder="Postal code"
              type="text"
              hasFloatingLabel
              sx={{ flex: 1 }}
              onChange={(e) => setQ(e.target.value)}
            />
            <Button
              onClick={() => onSearch(q)}
              sx={{ width: [null, 40], height: "auto" }}
            >
              Search
            </Button>
          </Flex>
        </Collapse>
        <Text
          sx={{
            color: hasError ? "red" : "inherit",
            display: hasError ? "block" : "none",
            fontSize: "sm",
            marginBlockStart: 1,
          }}
        >
          Select a parcel shop
        </Text>
      </Box>
    </>
  ) : null
}

export default ParcelWidget
