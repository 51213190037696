import * as React from "react"
import { useController } from "react-hook-form"

import { Input, InputProps } from "../ui/Input"
import { trackEmailAdded } from "../../../services/analytics"
import { useStore } from "../../../context/NewStoreContext"

export interface TextFieldProps extends Omit<InputProps, "onChange" | "value"> {
  name: string
  suggestion?: string
  customErrorMessage?: string
  checkLatin?: boolean
}

const TextField: React.FC<TextFieldProps> = ({
  name,
  suggestion,
  customErrorMessage,
  onBlur,
  checkLatin = false,
  ...props
}) => {
  const store = useStore()
  const { field, fieldState, formState } = useController({ name })

  React.useEffect(() => {
    if (suggestion) {
      field.onChange(suggestion)
    }
  }, [suggestion])

  let subLabel

  if ((formState.isDirty || formState.isSubmitted) && !!fieldState.error) {
    subLabel = customErrorMessage
      ? customErrorMessage
      : fieldState.error?.message
  }

  if (checkLatin) {
    const regex = /^[\x00-ʯ]*$/
    if (!regex.test(field.value)) {
      subLabel = "Please enter only Latin letters"
    }
  }

  return (
    <Input
      {...props}
      onChange={(text) => field.onChange(text)}
      onBlur={(e) => {
        if (e.target?.value) {
          trackEmailAdded(e.target.value, store.cart)
        }
        onBlur && onBlur(e)
        field.onBlur()
      }}
      value={field.value}
      hasError={
        ((formState.isDirty || formState.isSubmitted) && !!fieldState.error) ||
        subLabel
      }
      subLabel={subLabel}
    />
  )
}

export default TextField
