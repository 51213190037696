import * as React from "react"
import { useController } from "react-hook-form"

import { Select, SelectProps } from "../ui/Select"

export interface SelectFieldProps extends Omit<SelectProps, "onChange"> {
  name: string
}

const SelectField: React.FC<SelectFieldProps> = ({ name, ...props }) => {
  const { field, formState, fieldState } = useController({ name })

  return (
    <Select
      {...props}
      value={field.value}
      onValueChange={(value) => field.onChange(value)}
      hasFloatingDropdown
      hasError={
        (formState.isDirty || formState.isSubmitted) && !!fieldState.error
      }
      errorMessage={
        formState.isDirty || formState.isSubmitted
          ? fieldState.error?.message
          : undefined
      }
    />
  )
}

export default SelectField
