import * as React from "react"
import { useController } from "react-hook-form"
import { useStore } from "../../../context/NewStoreContext"
import { useRegions } from "medusa-react"

import { countryLookup } from "../../../utils/countries"
import { Select, SelectProps } from "../ui/Select"
import { orderBy } from "lodash-es"

import type { Address, Cart, StorePostCartsCartReq } from "@medusajs/medusa"

export interface CountrySelectFieldProps
  extends Omit<
    SelectProps,
    "data" | "value" | "hasFloatingDropdown" | "onValueChange"
  > {
  name: string
  shouldChangeCountry?: boolean
  isGiftCardOnly?: boolean
  shouldChangeBillingCountry?: boolean
  onValueChange?: (value: string, address?: Address) => void
}

export interface Country {
  value: string
  label: string
}

const CountrySelectField: React.FC<CountrySelectFieldProps> = ({
  name,
  shouldChangeCountry = true,
  isGiftCardOnly = false,
  shouldChangeBillingCountry = false,
  ...props
}) => {
  const store = useStore()
  const regions = useRegions()
  const [countries, setCountries] = React.useState<Country[] | []>([])
  const { field } = useController({ name })

  React.useEffect(() => {
    const loadCountries = async () => {
      const countries = regions.regions.flatMap((region) => {
        return region.countries.map((country) => {
          const countryDisplayName = countryLookup(country.iso_2.toUpperCase())

          return {
            value: country.iso_2,
            label: countryDisplayName,
          }
        })
      })

      setCountries(orderBy(countries, ["label"], ["asc"]))
    }

    if (regions?.regions?.length && !countries.length) {
      loadCountries().catch((err) => console.error(err))
    }
  }, [regions, countries])

  return (
    <Select
      {...props}
      hasFloatingDropdown
      value={field.value}
      data={
        countries?.map((country: Country) => {
          return {
            value: country.value,
            label: country.label,
          }
        }) ?? []
      }
      onValueChange={async (value) => {
        field.onChange(value)

        if (shouldChangeCountry) {
          const region = regions.regions.find((region) => {
            return region.countries.find((country) => {
              return country.iso_2 === value
            })
          })

          if (region) {
            let data: StorePostCartsCartReq = {
              country_code: value,
              region_id: region.id,
              shipping_address: {
                province: "",
                address_1: "",
                address_2: "",
                city: "",
                postal_code: "",
              },
            }

            if (isGiftCardOnly) {
              data = {
                billing_address: {
                  country_code: value,
                  province: "",
                  address_1: "",
                  address_2: "",
                  city: "",
                  postal_code: "",
                },
              }
            }

            const cartRes = await store.updateCart.mutateAsync(data)
            props.onValueChange?.(value, cartRes.cart.shipping_address)
          }
        } else {
          if (shouldChangeBillingCountry) {
            const cartRes = await store.updateCart.mutateAsync({
              billing_address: {
                country_code: value,
                province: "",
                address_1: "",
                address_2: "",
                city: "",
                postal_code: "",
              },
            })
            props.onValueChange?.(value, cartRes.cart.shipping_address)
          } else {
            props.onValueChange?.(value)
          }
        }
      }}
    />
  )
}

export default CountrySelectField
