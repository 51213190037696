// External packages
import * as React from "react"
import { StorePostCartsCartReq } from "@medusajs/medusa"
import { useLoadScript } from "@react-google-maps/api"
import {
  getCountries,
  isValidNumber,
  getCountryCallingCode,
  CountryCode,
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js"
import { Box, Flex, Paragraph } from "theme-ui"
import { z } from "zod"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Utilities
import { states } from "../../../utils/us-states"
import { caStates } from "../../../utils/ca-states"

// Services
import { trackCheckoutStepViewed } from "../../../services/analytics"
import Medusa from "../../../services/api"
import {
  trackCheckoutStepCompleted,
  trackNewsletterSubscription,
} from "../../../services/analytics"

// Components
import Form from "../form/Form"
import SubmitButton from "../form/SubmitButton"
import TextField from "../form/TextField"
import CheckboxField from "../form/CheckboxField"
import SelectField from "../form/SelectField"
import CountrySelectField from "../form/CountrySelectField"
import PlacesField from "../form/PlacesField"
import { CheckoutStepAction } from "./Checkout"
import { Button } from "../Button"
import { Collapse } from "../ui/Collapse"

const GOOGLE_PLACES_KEY = process.env.GATSBY_GOOGLE_PLACES_KEY || ""

const deliverySchema = z
  .object({
    email: z.string().email(),
    password: z.string().optional(),
    "first-name": z.string().min(1),
    "last-name": z.string().min(1),
    address_1: z
      .string()
      .min(1, "Enter a valid address")
      .max(50, "Max 50 characters. Enter remaining address in the next field."),
    address_2: z.string().optional(),
    "postal-code": z.string().min(1),
    city: z.string().min(1),
    country: z.string().min(1),
    "country-code": z.string().min(1),
    province: z.string().optional(),
    newsletter: z.boolean().optional(),
    phone: z.string(),
    "billing-address": z.boolean(),
    "billing-address-first-name": z.string().optional(),
    "billing-address-last-name": z.string().optional(),
    "billing-address-1": z.string().optional(),
    "billing-address-2": z.string().optional(),
    "billing-address-postal-code": z.string().optional(),
    "billing-address-city": z.string().optional(),
    "billing-address-country-code": z.string().min(1).optional(),
    "billing-address-phone": z.string().optional(),
    "billing-address-country": z.string().optional(),
    "billing-address-province": z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (
      (values?.country === "us" || values?.country === "ca") &&
      !values?.province
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Select a state",
        path: ["province"],
      })
    }

    const countryCallingData = countriesCodeSelectData.find(
      (c) => c.value === values["country-code"].toUpperCase()
    )

    if (
      values.phone.length >= countryCallingData.selectedLabel?.length &&
      values.phone.startsWith("+") &&
      !values.phone.startsWith(countryCallingData.selectedLabel ?? "")
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Please use national phone number format",
        path: ["phone"],
      })
    }

    if (
      !isValidNumber(
        values.phone,
        countryCallingData.value ??
          (values.country.toUpperCase() as CountryCode)
      )
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Enter a valid phone number",
        path: ["phone"],
      })
    }

    if (!values["billing-address"]) {
      if (!values["billing-address-first-name"]) {
        ctx.addIssue({
          code: "custom",
          message: "Enter a first name",
          path: ["billing-address-first-name"],
        })
      }

      if (!values["billing-address-last-name"]) {
        ctx.addIssue({
          code: "custom",
          message: "Enter a last name",
          path: ["billing-address-last-name"],
        })
      }

      if (!values["billing-address-1"]) {
        ctx.addIssue({
          code: "custom",
          message: "Enter an address",
          path: ["billing-address-1"],
        })
      }

      if (!values["billing-address-postal-code"]) {
        ctx.addIssue({
          code: "custom",
          message: "Enter a postal code",
          path: ["billing-address-postal-code"],
        })
      }

      if (!values["billing-address-city"]) {
        ctx.addIssue({
          code: "custom",
          message: "Enter a city",
          path: ["billing-address-city"],
        })
      }

      if (!values["billing-address-phone"]) {
        ctx.addIssue({
          code: "custom",
          message: "Enter a phone number",
          path: ["billing-address-phone"],
        })
      }

      if (!values["billing-address-country"]) {
        ctx.addIssue({
          code: "custom",
          message: "Enter a country",
          path: ["billing-address-country"],
        })
      }

      if (
        values["billing-address-country"] === "us" ||
        values["billing-address-country"] === "ca"
      ) {
        if (!values["billing-address-province"]) {
          ctx.addIssue({
            code: "custom",
            message: "Select a state",
            path: ["billing-address-province"],
          })
        }
      }
    }

    /* if (
      values.country === "jp" ||
      values.country === "kr" ||
      values.country === "cn"
    ) {
      const regex = /^[\x00-ʯ]*$/
      const fields = [
        "first-name",
        "last-name",
        "address_1",
        "address_2",
        "city",
        "password",
      ]
      fields.forEach((field) => {
        if (values[field] && !regex.test(values[field])) {
          ctx.addIssue({
            code: "custom",
            message: "Only english letters are allowed",
            path: [field],
          })
        }
      })
    } */
  })

export interface Suggestion {
  postal_code?: string
  city?: string
  province?: string
}

export const countriesCodeSelectData = getCountries().map((country) => {
  const getCountryName = new Intl.DisplayNames(["en"], { type: "region" })

  return {
    label: `${getCountryName.of(country)} (+${getCountryCallingCode(country)})`,
    selectedLabel: `+${getCountryCallingCode(country)}`,
    value: country,
  }
})

const getPhonePrefixCountry = (phoneNumber: string) => {
  if (!phoneNumber) {
    return ""
  }
  const parsedNumber = parsePhoneNumberFromString(phoneNumber)

  if (parsedNumber) {
    return parsedNumber.country
  } else {
    return ""
  }
}

const Details: React.FC<CheckoutStepAction> = ({
  previousStep,
  nextStep,
  previousStepLabel,
  nextStepLabel,
  isInEditable,
  isSwap,
}) => {
  const store = useStore()
  const [suggestion, setSuggestion] = React.useState<Suggestion | undefined>()

  const handleSuggestionChange = (suggestion: Suggestion) => {
    setSuggestion(suggestion)
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_PLACES_KEY,
    libraries: ["places"],
  })

  React.useEffect(() => {
    if (!isSwap) {
      trackCheckoutStepViewed(store?.cart, 1, "email/address")
    }
  }, [])

  const handleOnBlur = (name, value) => {
    if (isInEditable || !name || !value) {
      return
    }

    if (name === "email") {
      return store?.updateCart?.mutate({
        email: value,
      })
    }

    return store?.updateCart?.mutate({
      shipping_address: {
        [name]: value,
      },
    })
  }

  return (
    isLoaded && (
      <Form
        schema={deliverySchema}
        onSubmit={async (values) => {
          const callingCodeCountry = countriesCodeSelectData.find(
            (c) => c.value === values["country-code"]?.toUpperCase()
          )?.value

          const billingCallingCodeCountry = countriesCodeSelectData.find(
            (c) =>
              c.value === values["billing-address-country-code"]?.toUpperCase()
          )?.value

          const update: StorePostCartsCartReq = {
            email: values.email,
            shipping_address: {
              first_name: values["first-name"],
              last_name: values["last-name"],
              address_1: values.address_1,
              address_2: values.address_2,
              postal_code: values["postal-code"],
              city: values.city,
              phone: parsePhoneNumber(
                values.phone,
                callingCodeCountry ??
                  (values?.country.toUpperCase() as CountryCode)
              ).number,
              province:
                values?.country === "us" || values?.country === "ca"
                  ? values.province
                  : "",
            },
            billing_address: values["billing-address"]
              ? {
                  first_name: values["first-name"],
                  last_name: values["last-name"],
                  address_1: values.address_1,
                  address_2: values.address_2,
                  postal_code: values["postal-code"],
                  city: values.city,
                  phone: parsePhoneNumber(
                    values.phone,
                    callingCodeCountry ??
                      (values?.country.toUpperCase() as CountryCode)
                  ).number,
                  province:
                    values?.country === "us" || values?.country === "ca"
                      ? values.province
                      : "",
                  country_code: values.country,
                }
              : {
                  first_name: values["billing-address-first-name"],
                  last_name: values["billing-address-last-name"],
                  address_1: values["billing-address-1"],
                  address_2: values["billing-address-2"],
                  postal_code: values["billing-address-postal-code"],
                  city: values["billing-address-city"],
                  phone: parsePhoneNumber(
                    values["billing-address-phone"],
                    billingCallingCodeCountry ??
                      (values[
                        "billing-address-country"
                      ]?.toUpperCase() as CountryCode)
                  ).number,
                  country_code: values["billing-address-country"],
                  province:
                    values["billing-address-country"] === "us" ||
                    values["billing-address-country"] === "ca"
                      ? values["billing-address-province"]
                      : "",
                },
          }

          const res = await store?.updateCart?.mutateAsync(update)

          if (res?.cart) {
            if (values["newsletter"]) {
              const payload = {
                email: res.cart.email,
                first_name: res.cart.shipping_address.first_name,
                last_name: res.cart.shipping_address.last_name,
                ids: {},
                source: "archive_checkout",
              }

              await Medusa.newsletter.signup(payload)
              trackNewsletterSubscription(payload)
            }

            trackCheckoutStepCompleted(res.cart, 1)
            nextStep()
          }
        }}
        defaultValues={{
          email: store?.cart?.email ?? undefined,
          "first-name": store?.cart?.shipping_address?.first_name ?? undefined,
          "last-name": store?.cart?.shipping_address?.last_name ?? undefined,
          address_1: store?.cart?.shipping_address?.address_1 ?? undefined,
          address_2: store?.cart?.shipping_address?.address_2 ?? undefined,
          "postal-code":
            store?.cart?.shipping_address?.postal_code ?? undefined,
          city: store?.cart?.shipping_address?.city ?? undefined,
          country: store?.cart?.shipping_address?.country_code ?? undefined,
          "country-code":
            getPhonePrefixCountry(store.cart.shipping_address.phone) ||
            store?.cart?.shipping_address?.country_code?.toUpperCase() ||
            undefined,
          phone: store?.cart?.shipping_address?.phone
            ? store.cart.shipping_address.phone.replace(
                countriesCodeSelectData.find(
                  (c) =>
                    c.value ===
                    getPhonePrefixCountry(store.cart.shipping_address.phone)
                )?.selectedLabel ?? "",
                ""
              )
            : undefined,
          province: store?.cart?.shipping_address?.province ?? undefined,
          "billing-address-country":
            store?.cart?.billing_address?.country_code ?? undefined,
          "billing-address-province":
            store?.cart?.billing_address?.province ?? undefined,
          "billing-address-country-code":
            getPhonePrefixCountry(store.cart.billing_address?.phone) ||
            store?.cart?.billing_address?.country_code?.toUpperCase() ||
            getPhonePrefixCountry(store.cart.shipping_address?.phone) ||
            store?.cart?.shipping_address?.country_code?.toUpperCase() ||
            undefined,
          "billing-address-first-name":
            store?.cart?.billing_address?.first_name ?? undefined,
          "billing-address-last-name":
            store?.cart?.billing_address?.last_name ?? undefined,
          "billing-address-1":
            store?.cart?.billing_address?.address_1 ?? undefined,
          "billing-address-2":
            store?.cart?.billing_address?.address_2 ?? undefined,
          "billing-address-postal-code":
            store?.cart?.billing_address?.postal_code ?? undefined,
          "billing-address-city":
            store?.cart?.billing_address?.city ?? undefined,
          "billing-address-phone": store?.cart?.billing_address?.phone
            ? store.cart.billing_address.phone.replace(
                countriesCodeSelectData.find(
                  (c) =>
                    c.value ===
                    getPhonePrefixCountry(store.cart.billing_address.phone)
                )?.selectedLabel ?? "",
                ""
              )
            : undefined,
        }}
      >
        {({ resetField, setValue }) => (
          <>
            <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
              Delivery information
            </Paragraph>
            <TextField
              name="email"
              placeholder="Email"
              type="email"
              hasFloatingLabel
              customErrorMessage="Enter a valid email"
              sx={{ marginBlockEnd: 2 }}
              onBlur={(e) => {
                if (e.target.value) {
                  handleOnBlur("email", e.target.value)
                }
              }}
            />
            <CheckboxField
              name="newsletter"
              label="Sign me up for Tekla news and other digital communications"
              defaultChecked
              sx={{ marginBlockEnd: 6 }}
            />
            <Box sx={{ display: [null, "flex"], gap: 6 }}>
              <TextField
                name="first-name"
                placeholder="First name"
                hasFloatingLabel
                customErrorMessage="Enter a first name"
                sx={{ flex: 1, marginBlockEnd: 6 }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("first_name", e.target.value)
                  }
                }}
                checkLatin={true}
              />
              <TextField
                name="last-name"
                placeholder="Last name"
                hasFloatingLabel
                customErrorMessage="Enter a last name"
                sx={{ flex: 1, marginBlockEnd: 6 }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("last_name", e.target.value)
                  }
                }}
                checkLatin={true}
              />
            </Box>
            <CountrySelectField
              name="country"
              placeholder="Country"
              hasFloatingLabel
              sx={{ marginBlockEnd: 6 }}
              onValueChange={(value, shipping_address) => {
                if (value === "us" || value === "ca") {
                  resetField("province", {
                    defaultValue: shipping_address
                      ? shipping_address.province
                      : undefined,
                  })
                }

                resetField("address_1", {
                  defaultValue: shipping_address
                    ? shipping_address.address_1
                    : undefined,
                })
                resetField("address_2", {
                  defaultValue: shipping_address
                    ? shipping_address.address_2
                    : undefined,
                })
                resetField("postal-code", {
                  defaultValue: shipping_address
                    ? shipping_address.postal_code
                    : undefined,
                })
                resetField("city", {
                  defaultValue: shipping_address
                    ? shipping_address.city
                    : undefined,
                })
                setValue("country-code", value.toUpperCase())
              }}
            />
            {(store?.cart?.shipping_address?.country_code === "us" ||
              store?.cart?.shipping_address?.country_code === "ca") && (
              <SelectField
                name="province"
                placeholder="State"
                hasFloatingLabel
                data={
                  store?.cart?.shipping_address?.country_code === "us"
                    ? states
                    : caStates
                }
                sx={{ marginBlockEnd: 6 }}
              />
            )}
            <Box sx={{ marginBlockEnd: 2 }}>
              <PlacesField
                name="address_1"
                placeholder="Address"
                hasFloatingLabel
                onSuggestionChange={handleSuggestionChange}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("address_1", e.target.value)
                  }
                }}
                checkLatin={true}
              />
            </Box>
            <Collapse
              labelWhenOpened="Show less"
              labelWhenClosed="Company, apartment, floor, etc. (optional)"
              labelPosition="end"
              defaultOpened={!!store?.cart?.shipping_address?.address_2}
              sx={{ marginBlockEnd: 6 }}
            >
              <Box sx={{ paddingBlockStart: 4, paddingBlockEnd: 2 }}>
                <TextField
                  name="address_2"
                  placeholder="Apartment, unit, company etc."
                  hasFloatingLabel
                />
              </Box>
            </Collapse>
            <Flex sx={{ marginBlockEnd: 6, gap: 6 }}>
              <TextField
                name="postal-code"
                suggestion={suggestion?.postal_code ?? ""}
                placeholder="Postal code"
                hasFloatingLabel
                customErrorMessage="Enter a postal code"
                sx={{ width: "35%" }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("postal_code", e.target.value)
                  }
                }}
                checkLatin={true}
              />
              <TextField
                name="city"
                suggestion={suggestion?.city ?? ""}
                placeholder="City"
                hasFloatingLabel
                customErrorMessage="Enter a city"
                sx={{ flex: 1 }}
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("city", e.target.value)
                  }
                }}
                checkLatin={true}
              />
            </Flex>
            <Flex sx={{ gap: 6 }}>
              <SelectField
                name="country-code"
                placeholder="Country code"
                hasFloatingLabel
                hasFloatingDropdown
                data={countriesCodeSelectData}
                sx={{ width: 30 }}
              />
              <TextField
                name="phone"
                placeholder="Phone number"
                type="tel"
                hasFloatingLabel
                onBlur={(e) => {
                  if (e.target.value) {
                    handleOnBlur("phone", e.target.value)
                  }
                }}
                sx={{ flexGrow: 1 }}
              />
            </Flex>
            <CheckboxField
              name="billing-address"
              label="Use this address as my billing information"
              defaultChecked={true}
              subContentVisibilityReversed={true}
              subContent={
                <Box sx={{ paddingBlockStart: 4 }}>
                  <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
                    Billing address
                  </Paragraph>
                  <Box sx={{ display: [null, "flex"], gap: 6 }}>
                    <TextField
                      name="billing-address-first-name"
                      placeholder="First name"
                      hasFloatingLabel
                      sx={{ flex: 1, marginBlockEnd: 6 }}
                    />
                    <TextField
                      name="billing-address-last-name"
                      placeholder="Last name"
                      hasFloatingLabel
                      sx={{ flex: 1, marginBlockEnd: 6 }}
                    />
                  </Box>
                  <CountrySelectField
                    name="billing-address-country"
                    placeholder="Country"
                    hasFloatingLabel
                    shouldChangeCountry={false}
                    shouldChangeBillingCountry={true}
                    sx={{ marginBlockEnd: 6 }}
                    onValueChange={(value) => {
                      if (value === "us" || value === "ca") {
                        resetField("province")
                      }
                    }}
                  />
                  {(store?.cart?.billing_address?.country_code === "us" ||
                    store?.cart?.billing_address?.country_code === "ca") && (
                    <SelectField
                      name="billing-address-province"
                      placeholder="State"
                      hasFloatingLabel
                      data={
                        store?.cart?.billing_address?.country_code === "us"
                          ? states
                          : caStates
                      }
                      sx={{ marginBlockEnd: 6 }}
                    />
                  )}
                  <TextField
                    name="billing-address-1"
                    placeholder="Address"
                    hasFloatingLabel
                    sx={{ marginBlockEnd: 2 }}
                  />
                  <Collapse
                    labelWhenOpened="Show less"
                    labelWhenClosed="Company, apartment, floor, etc. (optional)"
                    labelPosition="end"
                    sx={{ marginBlockEnd: 6 }}
                  >
                    <Box sx={{ paddingBlockStart: 4, paddingBlockEnd: 2 }}>
                      <TextField
                        name="billing-address-2"
                        placeholder="Apartment, unit, company etc."
                        hasFloatingLabel
                      />
                    </Box>
                  </Collapse>
                  <Flex sx={{ marginBlockEnd: 6, gap: 6 }}>
                    <TextField
                      name="billing-address-postal-code"
                      placeholder="Postal code"
                      hasFloatingLabel
                      sx={{ width: "35%" }}
                    />
                    <TextField
                      name="billing-address-city"
                      placeholder="City"
                      hasFloatingLabel
                      sx={{ flex: 1 }}
                    />
                  </Flex>
                  <Flex sx={{ gap: 6 }}>
                    <SelectField
                      name="billing-address-country-code"
                      placeholder="Country code"
                      hasFloatingLabel
                      hasFloatingDropdown
                      data={countriesCodeSelectData}
                      sx={{ width: 30 }}
                    />
                    <TextField
                      name="billing-address-phone"
                      placeholder="Phone number"
                      type="tel"
                      hasFloatingLabel
                      sx={{ flexGrow: 1 }}
                    />
                  </Flex>
                </Box>
              }
              sx={{ marginBlockStart: 6 }}
            />
            <Flex
              sx={{
                flexDirection: ["column", "row"],
                gap: [4, 6],
                marginBlockStart: isInEditable ? 6 : 9,
              }}
            >
              <Button
                onClick={previousStep}
                variant="secondary"
                sx={{ flex: [null, 1], order: [2, "unset"] }}
              >
                {previousStepLabel ? previousStepLabel : "Back to shop"}
              </Button>
              <SubmitButton
                isVisuallyDisabled={store?.updateCart?.isLoading}
                sx={{ flex: [null, 1] }}
              >
                {nextStepLabel ? nextStepLabel : "Next"}
              </SubmitButton>
            </Flex>
          </>
        )}
      </Form>
    )
  )
}

export default Details
