import * as React from "react"
import { Box, BoxProps, BoxOwnProps, Paragraph } from "theme-ui"

interface SelectBoxProps
  extends Omit<React.ComponentPropsWithRef<"select">, "color">,
    BoxOwnProps {}

const SelectBox: React.FC<SelectBoxProps> = ({ sx, ...props }) => (
  <Box
    as="select"
    sx={{
      position: "absolute",
      width: "1px",
      height: "1px",
      border: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      clip: "rect(0px, 0px, 0px, 0px)",
      padding: 0,
      margin: "-1px",
      ...sx,
    }}
    {...(props as unknown as BoxProps)}
  />
)

export interface SelectProps extends SelectBoxProps {
  data: {
    value: string
    label: string
  }[]
  placeholder?: string
  hasFloatingLabel?: boolean
  hasError?: boolean
  errorMessage?: string
  onValueChange?: (value: string) => void
  defaultOpen?: boolean
}

export const Dropdown: React.FC<SelectProps> = ({
  data,
  placeholder,
  hasFloatingLabel,
  hasError,
  errorMessage,
  sx,
  value,
  onValueChange,
  defaultOpen = false,
  children,
  ...selectProps
}) => {
  const [options, setOptions] = React.useState({
    label: placeholder,
    value: null,
    isOpened: defaultOpen,
  })
  const selectRef = React.useRef(null)

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOptions({ ...options, isOpened: false })
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [options, selectRef])

  React.useEffect(() => {
    if (value && data.length && !options.value) {
      const selectedOption = data.find((option) => option.value === value)

      if (selectedOption) {
        setOptions({ label: selectedOption.label, value, isOpened: false })
      }
    }
  }, [value, data])

  return (
    <Box ref={selectRef} sx={{ position: "relative", ...sx }}>
      <Box
        as="ul"
        sx={{
          maxHeight: 75,
          overflowY: "scroll",
          color: "grayscale.600",
          fontSize: "xs",
          width: "100%",
          position: "absolute",
          top: defaultOpen ? 0 : 14,
          left: 0,
          opacity: options.isOpened ? 1 : 0,
          visibility: options.isOpened ? "visible" : "hidden",
          backgroundColor: "grayscale.white",
          border: "1px solid",
          borderColor: "grayscale.100",
          zIndex: "selectDropdown",
        }}
      >
        {data.map((option, index) => (
          <Box
            onClick={() => {
              setOptions({
                label: option.label,
                value: option.value,
                isOpened: false,
              })
              onValueChange && onValueChange(option.value)
            }}
            sx={{
              cursor: "pointer",
              color: options.value === option.value && "primary",
              backgroundColor:
                options.value === option.value && "grayscale.100",
              paddingInline: 4,
              transition: "color .2s, background-color .2s",
              ":hover": {
                color: "primary",
                backgroundColor: "grayscale.100",
              },
            }}
          >
            <Box
              sx={{
                borderBlockEnd: index + 1 !== data.length && "1px solid",
                borderBlockEndColor: "grayscale.100",
                paddingBlock: 3,
              }}
            >
              {option.label}
            </Box>
          </Box>
        ))}
      </Box>
      {errorMessage && (
        <Paragraph sx={{ color: "red", fontSize: "sm", marginBlockStart: 1 }}>
          {errorMessage}
        </Paragraph>
      )}
      <SelectBox {...selectProps}>
        {data.map((option) => (
          <option
            selected={options.value === option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </SelectBox>
    </Box>
  )
}
