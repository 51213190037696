import * as React from "react"

// External packages
import { Box, BoxProps, Flex, FlexProps, Paragraph, Image } from "theme-ui"
import { LineItem } from "@medusajs/medusa"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Utilities
import { contentfulSettings } from "../../../utils/contentful-settings"

// Components
import { Checkbox } from "../ui/Checkbox"

// TODO: replace with correct wrapping ids when imported
const SLEEPWEAR_BOX_WRAPPING_ID = "variant_01HDJXXQSHSKGMFYQX68RSB30V"
const BATHROBE_BOX_WRAPPING_ID = "variant_01HDJXWXGSGPWF9QJFMN5M0262"

const shouldShowEnvelopeGiftWrapping = (items: Readonly<LineItem>[]) => {
  if (
    items.some(
      (i) =>
        // Ensures we dont show envelope wrapping for down duvets, pillows and beach towels
        !i.variant?.sku?.startsWith("DN-HIGH") &&
        !i.variant?.sku?.startsWith("DN-MEDIUM") &&
        !i.variant?.sku?.startsWith("DN-LOW") &&
        !i.variant?.sku?.startsWith("DN-WS") &&
        !i.variant?.sku?.startsWith("DN-AS") &&
        !i.variant?.sku?.startsWith("DN-SS") &&
        !i.variant?.sku?.startsWith("DN-SG") &&
        i.variant?.sku !== "TT-LS-100x180" &&
        i.variant?.sku !== "TT-DS-100x180" &&
        i.variant?.sku !== "TT-IS-100x180"
    )
  ) {
    return true
  }

  return false
}

const giftWrappingProductionLine = (items: LineItem[]) => {
  const envelope = contentfulSettings.giftWrappingTogether

  const wrappings = {
    [SLEEPWEAR_BOX_WRAPPING_ID]: 0,
    [BATHROBE_BOX_WRAPPING_ID]: 0,
    // If one envelope is added, no need to add more - final number will be determined by warehouse
    [envelope]: 0,
    hasWrapping: false,
  }

  if (!items?.length) {
    return wrappings
  }

  if (items.every((i) => i.is_giftcard)) {
    return wrappings
  }

  // Bathrobe wrappings
  if (
    items.some(
      (i) =>
        i.variant?.sku?.startsWith("CB-") || i.variant?.sku?.startsWith("BT-")
    )
  ) {
    if (items.some((i) => i.variant?.sku?.startsWith("CB-"))) {
      const cbBathrobes = items.filter((i) => i.variant?.sku?.startsWith("CB-"))

      for (const item of cbBathrobes) {
        wrappings[BATHROBE_BOX_WRAPPING_ID] += item.quantity
      }
    }

    if (items.some((i) => i.variant?.sku?.startsWith("BT-"))) {
      const btBathrobes = items.filter((i) => i.variant?.sku?.startsWith("BT-"))

      for (const item of btBathrobes) {
        wrappings[BATHROBE_BOX_WRAPPING_ID] += item.quantity
      }
    }
  }

  let filteredItems: Readonly<LineItem>[] = items

  // Sleepwear wrappings
  if (
    filteredItems.some((i) => i.variant?.sku?.startsWith("SWP-")) ||
    filteredItems.some((i) => i.variant?.sku?.startsWith("SWE-")) ||
    filteredItems.some((i) => i.variant?.sku?.startsWith("SWS-")) ||
    filteredItems.some((i) => i.variant?.sku?.startsWith("SWT-"))
  ) {
    if (
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWP-")) &&
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWT-"))
    ) {
      let pairsOfSWPSWT: Readonly<LineItem>[] = filteredItems.filter(
        (i) =>
          i.variant?.sku?.startsWith("SWP-") ||
          i.variant?.sku?.startsWith("SWT-")
      )

      while (pairsOfSWPSWT.length) {
        const swpSleepwear = pairsOfSWPSWT.find((i) =>
          i.variant?.sku?.startsWith("SWP-")
        )
        const swtSleepwear = pairsOfSWPSWT.find((i) =>
          i.variant?.sku?.startsWith("SWT-")
        )

        if (!swpSleepwear?.quantity || !swtSleepwear?.quantity) {
          break
        }

        if (swpSleepwear.quantity === swtSleepwear.quantity) {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += swpSleepwear.quantity

          pairsOfSWPSWT = pairsOfSWPSWT.filter(
            (i) => i.id !== swpSleepwear.id && i.id !== swtSleepwear.id
          )
          filteredItems = filteredItems.filter(
            (i) => i.id !== swpSleepwear.id && i.id !== swtSleepwear.id
          )
        } else {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += Math.min(
            swpSleepwear.quantity,
            swtSleepwear.quantity
          )

          if (wrappings[envelope] === 0) {
            wrappings[envelope] = 1
          }

          if (swpSleepwear.quantity > swtSleepwear.quantity) {
            pairsOfSWPSWT = pairsOfSWPSWT
              .filter((i) => i.id !== swtSleepwear.id)
              .map((i) =>
                i.id === swpSleepwear.id
                  ? { ...i, quantity: i.quantity - swtSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== swtSleepwear.id)
              .map((i) =>
                i.id === swpSleepwear.id
                  ? { ...i, quantity: i.quantity - swtSleepwear.quantity }
                  : i
              )
          } else {
            pairsOfSWPSWT = pairsOfSWPSWT
              .filter((i) => i.id !== swpSleepwear.id)
              .map((i) =>
                i.id === swtSleepwear.id
                  ? { ...i, quantity: i.quantity - swpSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== swpSleepwear.id)
              .map((i) =>
                i.id === swtSleepwear.id
                  ? { ...i, quantity: i.quantity - swpSleepwear.quantity }
                  : i
              )
          }
        }
      }
    }

    if (
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWP-")) &&
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWE-"))
    ) {
      let pairsOfSWPSWE: Readonly<LineItem>[] = filteredItems.filter(
        (i) =>
          i.variant?.sku?.startsWith("SWP-") ||
          i.variant?.sku?.startsWith("SWE-")
      )

      while (pairsOfSWPSWE.length) {
        const swpSleepwear = pairsOfSWPSWE.find((i) =>
          i.variant?.sku?.startsWith("SWP-")
        )
        const sweSleepwear = pairsOfSWPSWE.find((i) =>
          i.variant?.sku?.startsWith("SWE-")
        )

        if (!swpSleepwear?.quantity || !sweSleepwear?.quantity) {
          break
        }

        if (swpSleepwear.quantity === sweSleepwear.quantity) {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += swpSleepwear.quantity

          pairsOfSWPSWE = pairsOfSWPSWE.filter(
            (i) => i.id !== swpSleepwear.id && i.id !== sweSleepwear.id
          )
          filteredItems = filteredItems.filter(
            (i) => i.id !== swpSleepwear.id && i.id !== sweSleepwear.id
          )
        } else {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += Math.min(
            swpSleepwear.quantity,
            sweSleepwear.quantity
          )

          if (wrappings[envelope] === 0) {
            wrappings[envelope] = 1
          }

          if (swpSleepwear.quantity > sweSleepwear.quantity) {
            pairsOfSWPSWE = pairsOfSWPSWE
              .filter((i) => i.id !== sweSleepwear.id)
              .map((i) =>
                i.id === swpSleepwear.id
                  ? { ...i, quantity: i.quantity - sweSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== sweSleepwear.id)
              .map((i) =>
                i.id === swpSleepwear.id
                  ? { ...i, quantity: i.quantity - sweSleepwear.quantity }
                  : i
              )
          } else {
            pairsOfSWPSWE = pairsOfSWPSWE
              .filter((i) => i.id !== swpSleepwear.id)
              .map((i) =>
                i.id === sweSleepwear.id
                  ? { ...i, quantity: i.quantity - swpSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== swpSleepwear.id)
              .map((i) =>
                i.id === sweSleepwear.id
                  ? { ...i, quantity: i.quantity - swpSleepwear.quantity }
                  : i
              )
          }
        }
      }
    }

    if (
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWS-")) &&
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWT-"))
    ) {
      let pairsOfSWSSWT: Readonly<LineItem>[] = filteredItems.filter(
        (i) =>
          i.variant?.sku?.startsWith("SWS-") ||
          i.variant?.sku?.startsWith("SWT-")
      )

      while (pairsOfSWSSWT.length) {
        const swsSleepwear = pairsOfSWSSWT.find((i) =>
          i.variant?.sku?.startsWith("SWS-")
        )
        const swtSleepwear = pairsOfSWSSWT.find((i) =>
          i.variant?.sku?.startsWith("SWT-")
        )

        if (!swsSleepwear?.quantity || !swtSleepwear?.quantity) {
          break
        }

        if (swsSleepwear.quantity === swtSleepwear.quantity) {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += swsSleepwear.quantity

          pairsOfSWSSWT = pairsOfSWSSWT.filter(
            (i) => i.id !== swsSleepwear.id && i.id !== swtSleepwear.id
          )
          filteredItems = filteredItems.filter(
            (i) => i.id !== swsSleepwear.id && i.id !== swtSleepwear.id
          )
        } else {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += Math.min(
            swsSleepwear.quantity,
            swtSleepwear.quantity
          )

          if (wrappings[envelope] === 0) {
            wrappings[envelope] = 1
          }

          if (swsSleepwear.quantity > swtSleepwear.quantity) {
            pairsOfSWSSWT = pairsOfSWSSWT
              .filter((i) => i.id !== swtSleepwear.id)
              .map((i) =>
                i.id === swsSleepwear.id
                  ? { ...i, quantity: i.quantity - swtSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== swtSleepwear.id)
              .map((i) =>
                i.id === swsSleepwear.id
                  ? { ...i, quantity: i.quantity - swtSleepwear.quantity }
                  : i
              )
          } else {
            pairsOfSWSSWT = pairsOfSWSSWT
              .filter((i) => i.id !== swsSleepwear.id)
              .map((i) =>
                i.id === swtSleepwear.id
                  ? { ...i, quantity: i.quantity - swsSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== swsSleepwear.id)
              .map((i) =>
                i.id === swtSleepwear.id
                  ? { ...i, quantity: i.quantity - swsSleepwear.quantity }
                  : i
              )
          }
        }
      }
    }

    if (
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWS-")) &&
      filteredItems.some((i) => i.variant?.sku?.startsWith("SWE-"))
    ) {
      let pairsOfSWSSWE: Readonly<LineItem>[] = filteredItems.filter(
        (i) =>
          i.variant?.sku?.startsWith("SWS-") ||
          i.variant?.sku?.startsWith("SWE-")
      )

      while (pairsOfSWSSWE.length) {
        const swsSleepwear = pairsOfSWSSWE.find((i) =>
          i.variant?.sku?.startsWith("SWS-")
        )
        const sweSleepwear = pairsOfSWSSWE.find((i) =>
          i.variant?.sku?.startsWith("SWE-")
        )

        if (!swsSleepwear?.quantity || !sweSleepwear?.quantity) {
          break
        }

        if (swsSleepwear.quantity === sweSleepwear.quantity) {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += swsSleepwear.quantity

          pairsOfSWSSWE = pairsOfSWSSWE.filter(
            (i) => i.id !== swsSleepwear.id && i.id !== sweSleepwear.id
          )
          filteredItems = filteredItems.filter(
            (i) => i.id !== swsSleepwear.id && i.id !== sweSleepwear.id
          )
        } else {
          wrappings[SLEEPWEAR_BOX_WRAPPING_ID] += Math.min(
            swsSleepwear.quantity,
            sweSleepwear.quantity
          )

          if (wrappings[envelope] === 0) {
            wrappings[envelope] = 1
          }

          if (swsSleepwear.quantity > sweSleepwear.quantity) {
            pairsOfSWSSWE = pairsOfSWSSWE
              .filter((i) => i.id !== sweSleepwear.id)
              .map((i) =>
                i.id === swsSleepwear.id
                  ? { ...i, quantity: i.quantity - sweSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== sweSleepwear.id)
              .map((i) =>
                i.id === swsSleepwear.id
                  ? { ...i, quantity: i.quantity - sweSleepwear.quantity }
                  : i
              )
          } else {
            pairsOfSWSSWE = pairsOfSWSSWE
              .filter((i) => i.id !== swsSleepwear.id)
              .map((i) =>
                i.id === sweSleepwear.id
                  ? { ...i, quantity: i.quantity - swsSleepwear.quantity }
                  : i
              )
            filteredItems = filteredItems
              .filter((i) => i.id !== swsSleepwear.id)
              .map((i) =>
                i.id === sweSleepwear.id
                  ? { ...i, quantity: i.quantity - swsSleepwear.quantity }
                  : i
              )
          }
        }
      }
    }

    // We have checked for all combinations of sleepwear items so this means there is a single piece of sleepwear
    if (
      wrappings[envelope] === 0 &&
      wrappings[SLEEPWEAR_BOX_WRAPPING_ID] === 0
    ) {
      wrappings[envelope] = 1
    }
  }

  // Filter bathrobe and sleepwear items to correctly display envelope wrapping
  filteredItems = filteredItems.filter(
    (i) =>
      !i.variant?.sku?.startsWith("CB-") &&
      !i.variant?.sku?.startsWith("BT-") &&
      !i.variant?.sku?.startsWith("SWP-") &&
      !i.variant?.sku?.startsWith("SWT-") &&
      !i.variant?.sku?.startsWith("SWE-") &&
      !i.variant?.sku?.startsWith("SWS-") &&
      !i.metadata?.is_wrapping &&
      !i.is_giftcard
  )

  if (
    wrappings[envelope] === 0 &&
    shouldShowEnvelopeGiftWrapping(filteredItems)
  ) {
    wrappings[envelope] = 1
  }

  if (
    wrappings[envelope] === 1 ||
    wrappings[SLEEPWEAR_BOX_WRAPPING_ID] ||
    wrappings[BATHROBE_BOX_WRAPPING_ID]
  ) {
    wrappings.hasWrapping = true
  }

  return wrappings
}

export const GiftWrapping = () => {
  const store = useStore()
  const [giftWrapping, setGiftWrapping] = React.useState(
    store.cart?.items?.some((i) => i?.metadata?.is_wrapping) ? true : false
  )
  const [key, setKey] = React.useState(0)

  const wrappings = giftWrappingProductionLine(store.cart?.items)

  const giftWrappingToggled = async () => {
    if (store.createLineItem.isLoading || store.removeLineItem.isLoading) {
      return
    }

    if (!giftWrapping) {
      if (wrappings[SLEEPWEAR_BOX_WRAPPING_ID]) {
        await store.createLineItem.mutateAsync({
          variant_id: SLEEPWEAR_BOX_WRAPPING_ID,
          quantity: wrappings[SLEEPWEAR_BOX_WRAPPING_ID],
          metadata: { is_wrapping: true },
        })
      }

      if (wrappings[BATHROBE_BOX_WRAPPING_ID]) {
        await store.createLineItem.mutateAsync({
          variant_id: BATHROBE_BOX_WRAPPING_ID,
          quantity: wrappings[BATHROBE_BOX_WRAPPING_ID],
          metadata: { is_wrapping: true },
        })
      }

      if (wrappings[contentfulSettings.giftWrappingTogether]) {
        await store.createLineItem.mutateAsync({
          variant_id: contentfulSettings.giftWrappingTogether,
          quantity: 1,
          metadata: { is_wrapping: true },
        })
      }
    } else {
      const wrappingIdsInCart = store.cart?.items?.filter(
        (i) => i.metadata?.is_wrapping
      )

      if (wrappingIdsInCart?.length) {
        for (const id of wrappingIdsInCart) {
          await store.removeLineItem.mutateAsync({ lineId: id.id })
        }
      }
    }

    setGiftWrapping(!giftWrapping)
  }

  React.useEffect(() => {
    if (store.removeLineItem.isLoading) {
      return
    }

    if (
      !wrappings.hasWrapping &&
      store.cart?.items?.length &&
      store.cart?.items?.some((i) => i.metadata?.is_wrapping)
    ) {
      const wrappingIdsInCart = store.cart?.items?.filter(
        (i) => i.metadata?.is_wrapping
      )

      if (wrappingIdsInCart?.length) {
        for (const id of wrappingIdsInCart) {
          store.removeLineItem.mutate({ lineId: id.id })
        }
      }
    } else if (
      wrappings.hasWrapping &&
      store.cart?.items.length &&
      store.cart?.items?.some((i) => i.metadata?.is_wrapping)
    ) {
      const wrappingIdsInCart = store.cart?.items?.filter(
        (i) => i.metadata?.is_wrapping
      )

      if (wrappingIdsInCart?.length) {
        let wrappingRemoved = false

        for (const id of wrappingIdsInCart) {
          if (
            !wrappings[id.variant_id] ||
            id.quantity !== wrappings[id.variant_id]
          ) {
            store.removeLineItem.mutate({ lineId: id.id })
            wrappingRemoved = true
          }
        }

        if (wrappingRemoved) {
          setGiftWrapping(false)
          setKey((prev) => prev + 1)
        }
      }
    }
  }, [store.cart?.items, wrappings])

  return wrappings.hasWrapping ? (
    <Box sx={{ marginBlockStart: 9 }}>
      <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
        Gift wrapping
      </Paragraph>
      <Checkbox
        key={key}
        label="Add complimentary gift wrapping"
        defaultChecked={Boolean(giftWrapping)}
        onChange={giftWrappingToggled}
        subContent={
          <Box sx={{ paddingBlockStart: 5 }}>
            <GiftWrappingList>
              {wrappings[SLEEPWEAR_BOX_WRAPPING_ID] ||
              wrappings[BATHROBE_BOX_WRAPPING_ID] ? (
                <GiftWrappingListItem
                  imageSrc="https://images.ctfassets.net/4g6al16haqoj/3eaXi6epBavvV9MjDCVnRh/4bf78ff2922acf4f417e51c524319394/Gift_box.jpeg?w=160&h=200&fm=webp"
                  label="Gift box"
                  content={
                    <Paragraph>
                      Bathrobes and sleepwear sets (1 x shirt and 1 x
                      pants/shorts) are packed and delivered in gift boxes.
                    </Paragraph>
                  }
                />
              ) : null}
              {wrappings[contentfulSettings.giftWrappingTogether] ? (
                <GiftWrappingListItem
                  imageSrc="https://images.ctfassets.net/4g6al16haqoj/7j8LM8wEVpVUyIvcUR2Wy9/6ee2313032e892b7be922bfedcc6af7b/Gift_envelope.jpeg?w=160&h=200&fm=webp"
                  label="Gift envelope"
                  content={
                    <Paragraph>
                      A gift envelope will be added to your order to be used at
                      your own discretion.
                    </Paragraph>
                  }
                />
              ) : null}
            </GiftWrappingList>
          </Box>
        }
      />
    </Box>
  ) : null
}

const GiftWrappingList: React.FC<BoxProps> = ({ sx, ...rest }) => (
  <Flex
    {...rest}
    sx={{
      ...sx,
      flexDirection: "column",
      gap: 6,
      fontSize: "xs",
      backgroundColor: "grayscale.100",
      cursor: "auto",
      padding: [3, 6],
    }}
  />
)

export interface GiftWrappingListItemProps extends FlexProps {
  imageSrc: string
  label: string
  content: React.ReactNode
}

const GiftWrappingListItem: React.FC<GiftWrappingListItemProps> = ({
  imageSrc,
  label,
  content,
  sx,
  ...rest
}) => (
  <Flex {...rest} sx={{ ...sx, gap: 6 }}>
    <Box sx={{ flexGrow: 0, flexShrink: 0, flexBasis: 20, width: 20 }}>
      <Image
        src={imageSrc}
        sx={{ display: "block", width: 20, height: 25, objectFit: "cover" }}
      />
    </Box>
    <Box>
      {label && (
        <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 2 }}>
          {label}
        </Paragraph>
      )}
      {content}
    </Box>
  </Flex>
)
