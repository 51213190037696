// External packages
import * as React from "react"
import { Box, Flex, FlexProps } from "theme-ui"

// Components
import { Collapse, CollapseProps } from "./ui/Collapse"

export const CarbonEmissionCollapse: React.FC<
  FlexProps & { label: CollapseProps["label"]; collapseProps?: CollapseProps }
> = ({ label, collapseProps, sx, children, ...rest }) => (
  <Flex
    {...rest}
    sx={{
      ...sx,
      gap: 2,
      "&::before": {
        content: "''",
        display: "block",
        width: 2,
        height: 2,
        flexShrink: 0,
        backgroundColor: "grayscale.400",
        borderRadius: 9999,
        position: "relative",
        top: "0.375rem",
      },
    }}
  >
    <Collapse
      {...collapseProps}
      label={label}
      labelStyle={{ fontSize: "sm", width: "auto" }}
      iconPosition="end"
    >
      <Box sx={{ overflow: "hidden", paddingBlockStart: 2 }}>{children}</Box>
    </Collapse>
  </Flex>
)
